import DvrIcon from '@mui/icons-material/Dvr';
import { DrawerMenu } from '../DashboardCardLists/menu-list';
import { PERMISSIONS } from '../roles-permissions-list';

export const DRAWER_VALUE_LIST: DrawerMenu[] = [
  {
    id: 'value-list',
    path: '/value-list',
    label: 'LISTES DE VALEURS',
    subLabel: 'Liste des valeurs',
    icon: DvrIcon,
    color: '',
    permissions: [PERMISSIONS.VALUE_LIST_LIST],
  },
];
