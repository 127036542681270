import ListIcon from '@mui/icons-material/List';
import SettingsIcon from '@mui/icons-material/Settings';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ChecklistIcon from '@mui/icons-material/Checklist';
import { DrawerMenu } from '../DashboardCardLists/menu-list';
import { PERMISSIONS } from '../roles-permissions-list';

export const DRAWER_NOMENCLATURES_LIST: DrawerMenu[] = [
  {
    id: 'nomenc',
    path: '/nomenc-formatted/list',
    label: 'NOMENCLATURES',
    subLabel: 'Liste des nomenclatures formattées',
    icon: ChecklistIcon,
    color: '',
    permissions: [PERMISSIONS.NOMENCLATURE_FORMATTED_LIST],
  },
  {
    id: 'nomenc',
    path: '/nomenc-coef/list',
    label: 'COEFFICIENTS',
    subLabel: 'Liste des coefficients',
    icon: ListIcon,
    color: '',
    permissions: [PERMISSIONS.NOMENCLATURE_COEF_LIST],
  },
  {
    id: 'paramètres',
    path: '/nomenc-coef/params',
    label: 'CONFIGURATION',
    subLabel: 'paramètres',
    icon: SettingsIcon,
    color: '',
    permissions: [PERMISSIONS.NOMENCLATURE_CONF],
  },
  {
    id: 'upload',
    path: '/nomenc-coef/upload',
    label: 'CHARGEMENT',
    subLabel: 'upload',
    icon: CloudUploadIcon,
    color: '',
    permissions: [PERMISSIONS.NOMENCLATURE_UPLOAD],
  },
];
