import { Location } from 'react-router-dom';
import { Buffer } from 'buffer';

export const getEmailFromToken = (location: Location) => {
  const query = new URLSearchParams(location.search);
  const token = query.get('token');
  const params = Buffer.from(token as string, 'base64').toString('ascii');
  const { email } = JSON.parse(params);
  return email;
};
