import { lazy } from 'react';
import { RoutesDefinition } from '../routes';
import { PERMISSIONS } from '../../utils/Lists/roles-permissions-list';

export const timeTrackingRoutes: RoutesDefinition[] = [
  {
    name: 'Time tracking Root',
    component: lazy(() => import('../../components/UI/DrawerRootEntry')),
    permissions: [],
    path: '/time-tracking',
  },
  {
    name: 'Liste Tâches et Actions',
    component: lazy(() => import('../../components/AdminInterface/TasksAndActions/ListTaskAction')),
    permissions: [PERMISSIONS.OPERATOR_TIME],
    path: '/time-tracking/list',
  },
  {
    name: 'Suivi temps réel',
    component: lazy(() => import('../../components/AdminInterface/TasksAndActions/RealTime')),
    permissions: [PERMISSIONS.OPERATOR_REAL_TIME],
    path: '/time-tracking/real-time',
  },
  {
    name: 'Flasher code barre',
    component: lazy(() => import('../../components/AdminInterface/TasksAndActions/FlashBarcode')),
    permissions: [PERMISSIONS.OPERATOR_FLASH_BARCODE],
    path: '/time-tracking/flash-barcode',
  },
  {
    name: 'Sections',
    component: lazy(() => import('../../components/AdminInterface/TasksAndActions/Sections')),
    permissions: [PERMISSIONS.SECTION_TIME],
    path: '/time-tracking/section',
  },
  {
    name: 'Indicateurs',
    component: lazy(() => import('../../components/AdminInterface/TasksAndActions/TimeTrackerIndicator')),
    permissions: [PERMISSIONS.TIME_INDICATOR],
    path: '/time-tracking/indicator',
  },
];
