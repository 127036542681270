import {
  AppBar, Toolbar, Box,
  useTheme,
} from '@mui/material';
import React from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { getSx } from './sx';
import CustomTypography from '../UI/CustomComponents/CustomTypography';
import CustomButton from '../UI/CustomComponents/CustomButton';
import { useAppDispatch, useAppSelector } from '../../utils/hook/UseReduxHook';
import { cleanUser } from '../../store/user/user.slice';
import OrganisationToolBar from './OrganisationLogo';
import PersonToolBar from './PersonLogo';

const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const sx = getSx(theme);
  const user = useAppSelector((state) => state.user.current);

  const handleLogout = () => {
    navigate('/login');
    dispatch(cleanUser());
  };

  return (
    <AppBar position="fixed" sx={sx.appBar}>
      <Toolbar sx={sx.toolBar}>
        <Box sx={sx.toolBarContent}>
          <Box sx={sx.titleWrapper}>
            <img
              src="/images/smallLogoPlanitools.jpg"
              alt="logo de la socité Planitools"
              style={{ height: '2rem' }}
            />
            <CustomTypography
              title={`${process.env.REACT_APP_VERSION || 'v1.0.0'}`}
              variant="subtitle1"
              sx={{ margin: 0 }}
            />
          </Box>
          {(user?.currentOrganisation?.code !== user?.code 
            || user?.organisation?.length > 0) && <OrganisationToolBar user={user} />}
          <PersonToolBar user={user} />

        </Box>

        <Box sx={sx.toolBarContent}>

          <CustomButton
            color="inherit"
            handler={handleLogout}
            iconButton
            icon={<LogoutIcon />}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
