import { createSlice } from '@reduxjs/toolkit';
import { CLEAN_ALL_STATES } from '../global-actions';
import { LabelState } from './label';
import { FETCH_UPLOAD_SCRIPT } from './label.thunk';

const initialState: LabelState = {
  list: [],
  isLoading: false,
};

export const labelSlice = createSlice({
  name: 'labelSlice',
  initialState,
  reducers: {
    cleanLabels(state) {
      return {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FETCH_UPLOAD_SCRIPT.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FETCH_UPLOAD_SCRIPT.fulfilled, (state, action) => ({
      ...state, list: action.payload, isLoading: false,
    }));
    builder.addCase(FETCH_UPLOAD_SCRIPT.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const LabelActions = {
  ...labelSlice.actions,
};
