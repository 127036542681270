import { createAsyncThunk } from '@reduxjs/toolkit';
import { UiSlice } from '../ui/ui.slice';
import axiosApiInstance from '../../config/axios';
import { ErrorApiModel } from '../../config/error-api.model';
import {
  AddArticleToForecastDto, CreateForecastDomainDto, DeleteArticleFromForecastDto,
  PatchForecastDateDto,
  UploadForecastFileDto, 
} from './forecast';

const { displayAlert, displayLoader } = UiSlice.actions;

const url: string = process.env.REACT_APP_API_BASE_URI || '/api';

export const FETCH_ALL_DOMAIN_FORECASTS = createAsyncThunk(
  'forecast/FETCH_ALL_DOMAIN_FORECASTS',
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.get(
        `${url}/forecasts`,
      );
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const UPLOAD_FORECASTS_FILE = createAsyncThunk(
  'forecast/UPLOAD_FILE',
  async (payload: UploadForecastFileDto, thunkApi) => {
    try {
      const { file, uploadRuleCode, forecastDomainCode } = payload;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('uploadRuleCode', uploadRuleCode);
      formData.append('forecastDomainCode', forecastDomainCode);

      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(`${url}/forecasts/forecast-file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Fichier envoyé avec succès',
          color: 'success',
          timeout: 6000,
        }),
      );

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Problème lors de l\'envoie du fichier',
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const FETCH_ARTICLES_BY_DATE_RANGE = createAsyncThunk(
  'forecast/FETCH_ARTICLES_BY_DATE_RANGE',
  async (payload: { code: string; beginDate: string; endDate: string }, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.get(
        `${url}/forecasts/${payload.code}/dates?beginDate=${payload.beginDate}&endDate=${payload.endDate}`,
      );
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const CREATE_FORECAST_DOMAIN = createAsyncThunk(
  'forecast/CREATE_FORECAST_DOMAIN',
  async (payload: CreateForecastDomainDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(`${url}/forecasts`, payload);
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Prévision créée avec succès',
          color: 'success',
          timeout: 6000,
        }),
      );

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const UPDATE_FORECAST_DOMAIN = createAsyncThunk(
  'forecast/UPDATE_FORECAST_DOMAIN',
  async (payload: CreateForecastDomainDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.patch(`${url}/forecasts`, payload);
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Prévision modifiée avec succès',
          color: 'success',
          timeout: 6000,
        }),
      );

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const ADD_ARTICLE_TO_DOMAIN_FORECAST = createAsyncThunk(
  'forecast/ADD_ARTICLE_TO_DOMAIN_FORECAST',
  async (payload: AddArticleToForecastDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(`${url}/forecasts/add-article`, payload);
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Article ajouté avec succès',
          color: 'success',
          timeout: 6000,
        }),
      );

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const DELETE_ARTICLE_FROM_DOMAIN_FORECAST = createAsyncThunk(
  'forecast/DELETE_ARTICLE_FROM_DOMAIN_FORECAST',
  async (payload: DeleteArticleFromForecastDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(`${url}/forecasts/delete-article`, payload);
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Article supprimé avec succès',
          color: 'success',
          timeout: 6000,
        }),
      );

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const UPDATE_ARTICLE_FORECAST_BY_DATE = createAsyncThunk(
  'forecast/UPDATE_ARTICLE_FORECAST_BY_DATE',
  async (payload: PatchForecastDateDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.patch(`${url}/forecasts/patch-forecast-date`, payload);
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Prévision modifiée avec succès',
          color: 'success',
          timeout: 6000,
        }),
      );

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);
