import { createAsyncThunk } from '@reduxjs/toolkit';
import { UiSlice } from '../ui/ui.slice';
import axiosApiInstance from '../../config/axios';
import { ErrorApiModel } from '../../config/error-api.model';
import {
  AddArticleToStockDto, CreateStockLocationDto, 
  DeleteArticleFromStockDto, PatchDailyStockDto, UploadStockFileDto, 
} from './stock';

const { displayAlert, displayLoader } = UiSlice.actions;

const url: string = process.env.REACT_APP_API_BASE_URI || '/api';

export const FETCH_ALL_STOCK_LOCATION = createAsyncThunk(
  'stock/FETCH_ALL_STOCK_LOCATION',
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.get(
        `${url}/stocks`,
      );
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const CREATE_STOCK_LOCATION = createAsyncThunk(
  'stock/CREATE_STOCK_LOCATION',
  async (payload: CreateStockLocationDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(`${url}/stocks`, payload);
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Lieu de stockage créé avec succès',
          color: 'success',
          timeout: 6000,
        }),
      );

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const UPDATE_STOCK_LOCATION = createAsyncThunk(
  'stock/UPDATE_STOCK_LOCATION',
  async (payload: CreateStockLocationDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.patch(`${url}/stocks`, payload);
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Lieu de stockage modifié avec succès',
          color: 'success',
          timeout: 6000,
        }),
      );

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const ADD_ARTICLE_TO_STOCK_LOCATION = createAsyncThunk(
  'stock/ADD_ARTICLE_TO_STOCK_LOCATION',
  async (payload: AddArticleToStockDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(`${url}/stocks/add-article`, payload);
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Article ajouté avec succès',
          color: 'success',
          timeout: 6000,
        }),
      );

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const DELETE_ARTICLE_FROM_STOCK_LOCATION = createAsyncThunk(
  'stock/DELETE_ARTICLE_FROM_STOCK_LOCATION',
  async (payload: DeleteArticleFromStockDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(`${url}/stocks/delete-article`, payload);
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Article supprimé avec succès',
          color: 'success',
          timeout: 6000,
        }),
      );

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const UPLOAD_STOCKS_FILE = createAsyncThunk(
  'stocks/UPLOAD_STOCKS_FILE',
  async (payload: UploadStockFileDto, thunkApi) => {
    try {
      const { file, uploadRuleCode, stockLocationCode } = payload;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('uploadRuleCode', uploadRuleCode);
      formData.append('stockLocationCode', stockLocationCode);

      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(`${url}/stocks/stock-file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Fichier envoyé avec succès',
          color: 'success',
          timeout: 6000,
        }),
      );

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Problème lors de l\'envoie du fichier',
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const FETCH_ARTICLES_STOCK_BY_DATE_RANGE = createAsyncThunk(
  'stock/FETCH_ARTICLES_STOCK_BY_DATE_RANGE',
  async (payload: { code: string; beginDate: string; endDate: string }, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.get(
        `${url}/stocks/${payload.code}/dates?beginDate=${payload.beginDate}&endDate=${payload.endDate}`,
      );
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const UPDATE_ARTICLE_STOCK_BY_DATE = createAsyncThunk(
  'stock/UPDATE_ARTICLE_STOCK_BY_DATE',
  async (payload: PatchDailyStockDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.patch(`${url}/stocks/patch-daily-stock`, payload);
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Stock modifié avec succès',
          color: 'success',
          timeout: 6000,
        }),
      );

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);
