import PersonIcon from '@mui/icons-material/Person';
import StoreIcon from '@mui/icons-material/Store';
import PeopleIcon from '@mui/icons-material/People';
import { DrawerMenu } from '../DashboardCardLists/menu-list';
import { PERMISSIONS } from '../roles-permissions-list';

export const DRAWER_ORGANISATIONS_USERS: DrawerMenu[] = [
  {
    id: 'organisations',
    path: '/organisations/list',
    label: 'ORGANISATIONS',
    subLabel: 'Gestion des organisations',
    icon: StoreIcon,
    color: '',
    permissions: [PERMISSIONS.ORGANISATION_LIST],
  },
  {
    id: 'users',
    path: '/users/list',
    label: 'UTILISATEURS ADMIN',
    subLabel: 'Utilisateurs admin',
    icon: PersonIcon,
    color: '',
    permissions: [PERMISSIONS.USER_LIST],
  },
  {
    id: 'operators',
    path: '/operators/list',
    label: 'OPERATEURS',
    subLabel: 'Gestion des opérateurs',
    icon: PeopleIcon,
    color: '',
    permissions: [PERMISSIONS.OPERATOR_LIST],
  },
];
