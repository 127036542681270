import axios, { AxiosError } from 'axios';
import { getToken } from '../utils/local-storage';
import { ErrorApiModelData } from '../utils/models/error-api.model';
import { ErrorApiModel } from './error-api.model';

const axiosApiInstance = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => ({
    ...config,
    headers: {
      Authorization: getToken() ? `Bearer ${getToken()}` : '',
      Accept: 'application/json',
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any),
  (error: AxiosError) => Promise.reject(error),
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    if (!response?.data) {
      // eslint-disable-next-line no-console
      console.error('API Error. No data!');
    }
    return response;
  },
  async (error: AxiosError) => Promise.reject(
    new ErrorApiModel(error.response?.data as ErrorApiModelData),
  ),
);

export default axiosApiInstance;
