import { createSlice } from '@reduxjs/toolkit';
import { CLEAN_ALL_STATES } from '../global-actions';
import { SupplierState } from './supplier';
import { CREATE_SUPPLIER, FETCH_ALL_SUPPLIERS, UPDATE_SUPPLIER } from './supplier.thunk';

const initialState: SupplierState = {
  list: [],
  isLoading: false,
};

export const supplierSlice = createSlice({
  name: 'supplierSlice',
  initialState,
  reducers: {
    cleanSuppliers(state) {
      return {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FETCH_ALL_SUPPLIERS.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FETCH_ALL_SUPPLIERS.fulfilled, (state, action) => ({
      ...state, list: action.payload, isLoading: false,
    }));
    builder.addCase(FETCH_ALL_SUPPLIERS.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CREATE_SUPPLIER.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CREATE_SUPPLIER.fulfilled, (state, action) => {
      state.list.push(action.payload);
      state.isLoading = false;
    });
    builder.addCase(CREATE_SUPPLIER.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(UPDATE_SUPPLIER.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(UPDATE_SUPPLIER.fulfilled, (state, action) => {
      state.list = state.list.map((supplierItem) => (supplierItem._id === action.payload._id
        ? action.payload : supplierItem));
      state.isLoading = false;
    });
    builder.addCase(UPDATE_SUPPLIER.rejected, (state) => {
      state.isLoading = false;
    }); 
    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const { cleanSuppliers } = supplierSlice.actions;

export const supplierActions = {
  ...supplierSlice.actions,
};
