import { createTheme } from '@mui/material/styles';
import { frFR } from '@mui/x-data-grid/locales';

const theme = createTheme({
  palette: {
    primary: {
      main: '#026A90',
      light: '#091215',
      contrastText: '#F4F2FA',
    },
    secondary: {
      main: '#BA2C73',
      light: '#EAEAEC',
    },
    info: {
      main: '#091215',
      light: '#015E80',
      contrastText: '#9F86C0',
    },
    error: {
      main: '#DC493A',
      light: '#FF7D00',
    },
    success: {
      main: '#519872',
      light: '#00BEDC',
      contrastText: '#8A5FFE',
    },
    warning: {
      main: '#FF7D00',
      light: '#4B5B64',
      contrastText: '#EAEAEC',
    },
  },
  typography: {
    fontFamily: [
      'Sora',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: 12,
    h1: {
      fontSize: '33px',
      lineHeight: '35px',
      fontWeight: 'bold',
      letterSpacing: '0px',
    },
    h2: {
      fontSize: '25px',
      lineHeight: '30px',
      fontWeight: 'bold',
      letterSpacing: '0px',
    },
    h3: {
      fontSize: '22px',
      lineHeight: '33px',
      fontWeight: 'bold',
      letterSpacing: '0.55px',
      textTransform: 'uppercase',
    },
    h4: {
      fontSize: '17px',
      lineHeight: '20px',
      fontWeight: 'bold',
      letterSpacing: '0.85px',
      textTransform: 'uppercase',
    },
    h5: {
      fontSize: '17px',
      lineHeight: '20px',
      fontWeight: 'bold',
      letterSpacing: '0px',
      textTransform: 'uppercase',
    },
    h6: {
      fontSize: '15px',
      lineHeight: '17px',
      fontWeight: 'bold',
      letterSpacing: '0.75px',
      textTransform: 'uppercase',
    },
    subtitle1: {
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 'bold',
      letterSpacing: '0.3px',
      textTransform: 'uppercase',
    },
    subtitle2: {
      fontSize: '17px',
      lineHeight: '20px',
      fontWeight: 'normal',
      letterSpacing: '0.42px',
    },
    button: {
      fontWeight: 600,
      lineHeight: 2.5,
      letterSpacing: '0.02857em',
      textTransform: 'none',
    },
  },
}, frFR);

export default theme;
