import { createAsyncThunk } from '@reduxjs/toolkit';
import { UiSlice } from '../ui/ui.slice';
import { CreateColumnDto, DeleteColumnDto, GetColumnsParametersDto } from './column.d';
import axiosApiInstance from '../../config/axios';
import { ErrorApiModel } from '../../config/error-api.model';

const { displayAlert, displayLoader } = UiSlice.actions;

const url: string = process.env.REACT_APP_API_BASE_URI || '/api';

export const GET_COLLECTION_COLUMNS = createAsyncThunk(
  'columns/GET_COLLECTION_COLUMNS',
  async (payload: GetColumnsParametersDto, thunkApi) => {
    try {
      const { collectionName } = payload;
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.get(
        `${url}/columns/${collectionName}`,
      );
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const CREATE_COLLECTION_COLUMN = createAsyncThunk(
  'columns/CREATE_COLLECTION_COLUMN',
  async (payload: CreateColumnDto, thunkApi) => {
    try {
      const { collectionName, column } = payload;
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(`${url}/columns`, { collectionName, column });
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Colonne créée avec succès',
          color: 'success',
          timeout: 6000,
        }),
      );

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const UPDATE_COLLECTION_COLUMN = createAsyncThunk(
  'columns/UPDATE_COLLECTION_COLUMN',
  async (payload: CreateColumnDto, thunkApi) => {
    try {
      const { collectionName, column } = payload;
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.patch(`${url}/columns`, { collectionName, column });
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Colonne modifiée avec succès',
          color: 'success',
          timeout: 6000,
        }),
      );

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const DELETE_COLLECTION_COLUMN = createAsyncThunk(
  'columns/DELETE_COLLECTION_COLUMN',
  async (payload: DeleteColumnDto, thunkApi) => {
    try {
      const { collectionName, columnId } = payload;
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.delete(`${url}/columns`, {
        params: { collectionName, columnId },
      });
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Colonne supprimée avec succès',
          color: 'success',
          timeout: 6000,
        }),
      );

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);
