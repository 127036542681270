/* eslint-disable no-lone-blocks */
import React, { Suspense } from 'react';
import {
  Routes, Route,
} from 'react-router-dom';
import { publicRoutes } from './public';
import { adminRoutes } from './admin';
import { RoutesDefinition, RoutingProps } from './routes';
import NotFound from '../components/UI/CustomComponents/NotFound';
import { DRAWER_ARTICLES_LIST } from '../utils/Lists/DrawerLists/articles-list';
import { organisationsAndUsersRoutes } from './orgnisations-users';
import useDrawer from '../utils/hook/use-drawer';
import CustomLoader from '../components/UI/CustomComponents/CustomLoader';
import { articlesRoutes } from './articles';
import { DRAWER_ORGANISATIONS_USERS } from '../utils/Lists/DrawerLists/users-list';
import { uploadRulesRoutes } from './upload-rules';
import { DRAWER_UPLOAD_RULES_LIST } from '../utils/Lists/DrawerLists/upload-rules-list';
import { DRAWER_CORRESPONDENCE_TABLE } from '../utils/Lists/DrawerLists/correspondence-table-list';
import { DRAWER_VALUE_LIST } from '../utils/Lists/DrawerLists/value-list';
import { correspondenceTableRoutes } from './correspondence-table';
import { nomenclaturesRoutes } from './nomenc';
import { DRAWER_NOMENCLATURES_LIST } from '../utils/Lists/DrawerLists/nomenc-list';
import { forecastsRoutes } from './forecasts';
import { DRAWER_FORECASTS_LIST } from '../utils/Lists/DrawerLists/forecasts-list';
import { stockRoutes } from './stock';
import { STOCK_LOCATION_LIST } from '../utils/Lists/DrawerLists/stock-list';
import { productionReferentialRoutes } from './production-referential';
import { DRAWER_PRODUCTION_REFERENTIAL } from '../utils/Lists/DrawerLists/production-referential-list';
import { productionOrdersRoutes } from './production-orders';
import { DRAWER_PRODUCTION_ORDERS_LIST } from '../utils/Lists/DrawerLists/production-order-list';
import { timeTrackingRoutes } from './time-tracking';
import { DRAWER_TIME_TRACKING_LIST } from '../utils/Lists/DrawerLists/time-tracking-list';
import { valueListRoutes } from './value-list';
import { filterListByUserPermissions } from '../utils/functions/filterListByUser';
import { supplierListRoutes } from './supplier-list';
import { DRAWER_SUPPLIER_LIST } from '../utils/Lists/DrawerLists/supplier-list';

const Routing: React.FC<RoutingProps> = ({ user }) => (
  <Suspense fallback={<CustomLoader />}>
    <Routes>
      {/* CREATING PUBLIC ROUTES */}
      {publicRoutes.map((routeItem: RoutesDefinition) => {
        if (routeItem.component) {
          return (
            <Route
              key={routeItem.path}
              path={routeItem.path}
              element={<routeItem.component />}
            />
          );
        }
        return null;
      })}
      { /* CREATING ADMIN ROUTES */ }
      {user._id
        && filterListByUserPermissions(adminRoutes, user).map((routeItem: RoutesDefinition) => {
          if (routeItem.component) {
            return (
              <Route
                key={routeItem.path}
                path={routeItem.path}
                element={<routeItem.component />}
              />
            );
          }
          return null;
        })}

      {/* CREATING ARTICLES ROUTES */}
      {filterListByUserPermissions(articlesRoutes, user).map((routeItem: RoutesDefinition) => {
        if (routeItem.component) {
          const ComponentWithDrawer = useDrawer(routeItem.component, DRAWER_ARTICLES_LIST, user);
          return (
            <Route
              key={routeItem.path}
              path={routeItem.path}
              element={<ComponentWithDrawer />}
            />
          );
        }
        return null;
      })}

      {/* CREATING NOMENC ROUTES */}
      {filterListByUserPermissions(nomenclaturesRoutes, user).map((routeItem: RoutesDefinition) => {
        if (routeItem.component) {
          const ComponentWithDrawer = useDrawer(routeItem.component, DRAWER_NOMENCLATURES_LIST, user);
          return (
            <Route
              key={routeItem.path}
              path={routeItem.path}
              element={<ComponentWithDrawer />}
            />
          );
        }
        return null;
      })}

      {/* CREATING FORECASTS ROUTES */}
      {filterListByUserPermissions(forecastsRoutes, user).map((routeItem: RoutesDefinition) => {
        if (routeItem.component) {
          const ComponentWithDrawer = useDrawer(routeItem.component, DRAWER_FORECASTS_LIST, user);
          return (
            <Route
              key={routeItem.path}
              path={routeItem.path}
              element={<ComponentWithDrawer />}
            />
          );
        }
        return null;
      })}

      {/* CREATING STOCK ROUTES */}
      {filterListByUserPermissions(stockRoutes, user).map((routeItem: RoutesDefinition) => {
        if (routeItem.component) {
          const ComponentWithDrawer = useDrawer(routeItem.component, STOCK_LOCATION_LIST, user);
          return (
            <Route
              key={routeItem.path}
              path={routeItem.path}
              element={<ComponentWithDrawer />}
            />
          );
        }
        return null;
      })}

      {/* CREATING USERS ROUTES */}
      {filterListByUserPermissions(organisationsAndUsersRoutes, user).map((routeItem: RoutesDefinition) => {
        if (routeItem.component) {
          const ComponentWithDrawer = useDrawer(routeItem.component, DRAWER_ORGANISATIONS_USERS, user);
          return (
            <Route
              key={routeItem.path}
              path={routeItem.path}
              element={<ComponentWithDrawer />}
            />
          );
        }
        return null;
      })}

      {/* CREATING PRODUCTION REFERENTIAL ROUTES */}
      {filterListByUserPermissions(productionReferentialRoutes, user).map((routeItem: RoutesDefinition) => {
        if (routeItem.component) {
          const ComponentWithDrawer = useDrawer(routeItem.component, DRAWER_PRODUCTION_REFERENTIAL, user);
          return (
            <Route
              key={routeItem.path}
              path={routeItem.path}
              element={<ComponentWithDrawer />}
            />
          );
        }
        return null;
      })}

      {/* CREATING UPLOAD RULES ROUTES */}
      {filterListByUserPermissions(uploadRulesRoutes, user).map((routeItem: RoutesDefinition) => {
        if (routeItem.component) {
          const ComponentWithDrawer = useDrawer(routeItem.component, DRAWER_UPLOAD_RULES_LIST, user);
          return (
            <Route
              key={routeItem.path}
              path={routeItem.path}
              element={<ComponentWithDrawer />}
            />
          );
        }
        return null;
      })}

      {/* CREATING CORRESPONDANCE TABLE */}
      {filterListByUserPermissions(correspondenceTableRoutes, user).map((routeItem: RoutesDefinition) => {
        if (routeItem.component) {
          const ComponentWithDrawer = useDrawer(routeItem.component, DRAWER_CORRESPONDENCE_TABLE, user);
          return (
            <Route
              key={routeItem.path}
              path={routeItem.path}
              element={<ComponentWithDrawer />}
            />
          );
        }
        return null;
      })}
      
      {/* CREATING VALUE LIST */}
      {filterListByUserPermissions(valueListRoutes, user).map((routeItem: RoutesDefinition) => {
        if (routeItem.component) {
          const ComponentWithDrawer = useDrawer(routeItem.component, DRAWER_VALUE_LIST, user);
          return (
            <Route
              key={routeItem.path}
              path={routeItem.path}
              element={<ComponentWithDrawer />}
            />
          );
        }
        return null;
      })}
      
      {/* CREATING SUPPLIER LIST */}
      {filterListByUserPermissions(supplierListRoutes, user).map((routeItem: RoutesDefinition) => {
        if (routeItem.component) {
          const ComponentWithDrawer = useDrawer(routeItem.component, DRAWER_SUPPLIER_LIST, user);
          return (
            <Route
              key={routeItem.path}
              path={routeItem.path}
              element={<ComponentWithDrawer />}
            />
          );
        }
        return null;
      })}

      {/* CREATING PRODUCTION ORDER ROUTES */}
      {filterListByUserPermissions(productionOrdersRoutes, user).map((routeItem: RoutesDefinition) => {
        if (routeItem.component) {
          const ComponentWithDrawer = useDrawer(routeItem.component, DRAWER_PRODUCTION_ORDERS_LIST, user);
          return (
            <Route
              key={routeItem.path}
              path={routeItem.path}
              element={<ComponentWithDrawer />}
            />
          );
        }
        return null;
      })}

      {/* CREATING TIME TRACKING ROUTES */}
      {filterListByUserPermissions(timeTrackingRoutes, user).map((routeItem: RoutesDefinition) => {
        if (routeItem.component) {
          const ComponentWithDrawer = useDrawer(routeItem.component, DRAWER_TIME_TRACKING_LIST, user);
          return (
            <Route
              key={routeItem.path}
              path={routeItem.path}
              element={<ComponentWithDrawer />}
            />
          );
        }
        return null;
      })}

      { /* CREATING a 404 Route */ }
      <Route path="*" element={<NotFound />} />
    </Routes>
  </Suspense>
);

export default Routing;
