import { createSlice } from '@reduxjs/toolkit';
import { UploadRuleList } from './upload-rule';
import { CLEAN_ALL_STATES } from '../global-actions';
import {
  CREATE_UPLOAD_RULE, DELETE_UPLOAD_RULE, GET_UPLOAD_RULES, GET_UPLOAD_RULES_BY_COLLECTION, UPDATE_UPLOAD_RULE,
} from './upload-rule.thunk';

const initialState: UploadRuleList = {
  list: [],
  isLoading: false,
};

export const uploadRuleSlice = createSlice({
  name: 'uploadRuleSlice',
  initialState,
  reducers: {
    cleanUploadRules(state) {
      return {
        ...state,
        ...initialState,
      };
    },
    updateUploadRule(state, action) {
      state.list = state.list.map((ruleItem) => (ruleItem._id === action.payload._id
        ? action.payload : ruleItem));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GET_UPLOAD_RULES.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(GET_UPLOAD_RULES.fulfilled, (state, action) => ({
      ...state, list: action.payload, isLoading: false,
    }));
    builder.addCase(GET_UPLOAD_RULES.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(GET_UPLOAD_RULES_BY_COLLECTION.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(GET_UPLOAD_RULES_BY_COLLECTION.fulfilled, (state, action) => ({
      ...state, list: action.payload, isLoading: false,
    }));
    builder.addCase(GET_UPLOAD_RULES_BY_COLLECTION.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CREATE_UPLOAD_RULE.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CREATE_UPLOAD_RULE.fulfilled, (state, action) => {
      state.list.push(action.payload);
    });
    builder.addCase(CREATE_UPLOAD_RULE.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(UPDATE_UPLOAD_RULE.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(UPDATE_UPLOAD_RULE.fulfilled, (state, action) => {
      state.list = state.list.map((ruleItem) => (ruleItem._id === action.payload._id
        ? action.payload : ruleItem));
      state.isLoading = false;
    });
    builder.addCase(UPDATE_UPLOAD_RULE.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(DELETE_UPLOAD_RULE.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(DELETE_UPLOAD_RULE.fulfilled, (state, action) => {
      state.list = state.list.filter((ruleItem) => (ruleItem._id !== action.payload._id));
      state.isLoading = false;
    });
    builder.addCase(DELETE_UPLOAD_RULE.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const { cleanUploadRules, updateUploadRule } = uploadRuleSlice.actions;

export const UploadRulesActions = {
  ...uploadRuleSlice.actions,
};
