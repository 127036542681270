import { lazy } from 'react';
import { RoutesDefinition } from '../routes';
import { PERMISSIONS } from '../../utils/Lists/roles-permissions-list';

export const supplierListRoutes: RoutesDefinition[] = [
  {
    name: 'Listes des fournisseurs',
    component: lazy(() => import('../../components/AdminInterface/Suppliers/index')),
    permissions: [PERMISSIONS.SUPPLIER_LIST],
    path: '/supplier-list',
  },
];
