import { createAsyncThunk } from '@reduxjs/toolkit';
import { UiSlice } from '../ui/ui.slice';
import type { CreateOrganisationDto, UpdateOrganisationDto } from './organisation';
import axiosApiInstance from '../../config/axios';
import { ErrorApiModel } from '../../config/error-api.model';

const { displayAlert, displayLoader } = UiSlice.actions;

const url: string = process.env.REACT_APP_API_BASE_URI || '/api';

export const FETCH_ORGANISATIONS = createAsyncThunk(
  'organisation/FETCH_ORGANISATIONS',
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.get(
        `${url}/organisations`,
      );
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const CREATE_ORGANISATION = createAsyncThunk(
  'organisation/CREATE_ORGANISATION',
  async (data: CreateOrganisationDto, thunkApi) => {
    try {
      const result = await axiosApiInstance.post(`${url}/organisations`, {
        ...data,
      });
      thunkApi.dispatch(
        displayAlert({
          message:
            'Organisation créée avec succès',
          color: 'success',
          timeout: 3000,
        }),
      );
      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 5000,
        }),
      );
      throw error;
    }
  },
);

export const UPDATE_ORGANISATION = createAsyncThunk(
  'organisation/UPDATE_ORGANISATION',
  async (data: UpdateOrganisationDto, thunkApi) => {
    try {
      const result = await axiosApiInstance.patch(`${url}/organisations`, {
        ...data,
      });
      thunkApi.dispatch(
        displayAlert({
          message: 'Organisation modifiée avec succès',
          color: 'success',
          timeout: 3000,
        }),
      );
      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 5000,
        }),
      );
      throw error;
    }
  },
);

export const DELETE_ORGANISATION = createAsyncThunk(
  'organisation/DELETE_ORGANISATION',
  async (organisationCode: string, thunkApi) => {
    try {
      const result = await axiosApiInstance.delete(`${url}/organisations/${organisationCode}`);
      thunkApi.dispatch(
        displayAlert({
          message: 'Organisation supprimée avec succès',
          color: 'success',
          timeout: 3000,
        }),
      );
      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 5000,
        }),
      );
      throw error;
    }
  },
);
