import { createSlice } from '@reduxjs/toolkit';

import {
  CREATE_OPERATOR, FETCH_OPERATORS,
  UPDATE_OPERATOR,
} from './operator.thunk';
import { OperatorsState } from './operator';
import { CLEAN_ALL_STATES } from '../global-actions';
import { DELETE_USER } from '../user/user.thunk';

const initialState: OperatorsState = {
  list: [],
  isLoading: false,
  current: {
    _id: '',
    code: '',
    name: '',
    surname: '',
    roles: [],
    organisation: [],
  },
};

export const operatorSlice = createSlice({
  name: 'operator',
  initialState,
  reducers: {
    saveOperator: (state, action) => {
      state.current = { ...action.payload };
    },
    cleanOperator: () => initialState,
    cleanOperatorsList: (state) => {
      state.list = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(CREATE_OPERATOR.fulfilled, (state, action) => {
      state.list.push(action.payload);
      state.isLoading = false;
    });
    builder.addCase(CREATE_OPERATOR.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CREATE_OPERATOR.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(UPDATE_OPERATOR.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(UPDATE_OPERATOR.fulfilled, (state, action) => {
      state.list = state.list.map((operatorItem) => {
        if (operatorItem._id === action.payload._id) {
          return action.payload;
        }
        return operatorItem;
      });
      state.isLoading = false;
    });
    builder.addCase(UPDATE_OPERATOR.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(FETCH_OPERATORS.fulfilled, (state, action) => {
      state.list = action.payload;
      state.isLoading = false;
    });
    builder.addCase(FETCH_OPERATORS.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FETCH_OPERATORS.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(DELETE_USER.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(DELETE_USER.fulfilled, (state, action) => {
      state.list = state.list.filter((userItem) => (userItem._id !== action.payload._id));
      state.isLoading = false;
    });
    builder.addCase(DELETE_USER.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const OperatorActions = {
  ...operatorSlice.actions,
  CREATE_OPERATOR,
  FETCH_OPERATORS,
};
