/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import { TextField, Box } from '@mui/material';
import { Controller } from 'react-hook-form';
import { IProps, nonBaseTextFieldProps } from './custom-controlled-textfield';

const CustomControlledTextField: React.FC<IProps & nonBaseTextFieldProps> = ({
  noWrapper = false,
  size = 'small',
  label = '',
  name = '',
  type = 'string',
  rows = undefined,
  fullWidth = true,
  isFocused = false,
  inputProps = {},
  InputProps = {},
  InputLabelProps = {},
  variant = 'outlined',
  error = undefined,
  errorMessage = '',
  defaultValue = '',
  disabled = false,
  required = false,
  control = undefined,
  autocomplete = '',
  inputRef = undefined,
  customOnChange = undefined,
  customOnBlur = undefined,
  sx = undefined,
}) => (
  <>
    {!noWrapper && (
    <Box
      sx={{
        width: '80%',
        margin: '1rem',
      }}
    >
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextField
            required={required}
            autoComplete={autocomplete}
            type={type}
            id={name}
            variant={variant}
            label={label}
            size={size}
            multiline={!!rows}
            rows={rows}
            inputProps={inputProps}
            InputProps={InputProps}
            InputLabelProps={InputLabelProps}
            error={error}
            helperText={errorMessage}
            fullWidth={fullWidth}
            autoFocus={isFocused}
            defaultValue={defaultValue}
            disabled={disabled}
            onChange={(val) => {
              if (customOnChange) {
                customOnChange(val);
              }
              onChange(val);
            }}
            onBlur={(val) => {
              if (customOnBlur) {
                customOnBlur(val);
              }
              onBlur();
            }}
            inputRef={inputRef}
            value={value || ''}
            sx={sx}
          />
        )}
      />
    </Box>
    )}
    {noWrapper && (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => (
        <TextField
          required={required}
          autoComplete={autocomplete}
          type={type}
          id={name}
          variant={variant}
          label={label}
          size={size}
          multiline={!!rows}
          rows={rows}
          inputProps={inputProps}
          InputProps={InputProps}
          InputLabelProps={InputLabelProps}
          error={error}
          helperText={errorMessage}
          fullWidth={fullWidth}
          autoFocus={isFocused}
          defaultValue={defaultValue}
          disabled={disabled}
          onChange={(val) => {
            onChange(val);
            if (customOnChange) {
              customOnChange(val);
            }
          }}
          onBlur={(val) => {
            onBlur();
            if (customOnBlur) {
              customOnBlur(val);
            }
          }}
          inputRef={inputRef}
          value={value || ''}
          sx={sx}
        />
      )}
    />
    )}
  </>
);

export default CustomControlledTextField;
