import React, { useRef, useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import { Box } from '@mui/system';
import { useTheme } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { PersonLogoProps } from './person';
import CustomTypography from '../../UI/CustomComponents/CustomTypography';
import { getSx } from './sx';
import UserListMenu from '../Menus/UserListMenu/UserListMenu';

const PersonLogo: React.FC<PersonLogoProps> = ({ user }) => {
  const theme = useTheme();
  const sx = getSx(theme);
  const userListMenu = useRef(null);
  const [userListOpen, setUserListOpen] = useState(false);

  const isOperatorOnly = (roles: string[]): boolean => roles?.includes('OPERATOR') && !roles?.includes('ADMIN');

  return (
    <>
      <Box sx={sx.outerBox} onClick={() => setUserListOpen(true)} ref={userListMenu}>
        <Box sx={sx.iconBox}>
          <PersonIcon sx={sx.icon} />
        </Box>
        <Box sx={sx.typoBox}>
          <CustomTypography
            title={`${user?.name?.toUpperCase()}`}
            variant="h6"
            sx={sx.workstationTitle}
          />
          <CustomTypography
            title={`${isOperatorOnly(user.roles) ? 'OPERATEUR' : 'ADMIN'}`}
            sx={sx.workstationSubtitle}
          />
        </Box>
        <ArrowDropDownIcon />
      </Box>
      <UserListMenu
        anchorEl={userListMenu.current}
        isOpen={userListOpen}
        onClose={() => setUserListOpen(false)}
        user={user}
      />
    </>
  );
};

export default PersonLogo;
