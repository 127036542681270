import { lazy } from 'react';
import { RoutesDefinition } from '../routes';
import { PERMISSIONS } from '../../utils/Lists/roles-permissions-list';

export const correspondenceTableRoutes: RoutesDefinition[] = [
  {
    name: 'Liste Table de correspondance',
    component: lazy(() => import('../../components/AdminInterface/CorrespondenceTable/index')),
    permissions: [PERMISSIONS.MAPPING_TABLE_LIST],
    path: '/correspondence-table',
  },
];
