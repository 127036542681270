import { Theme } from '@mui/material';

export const getSx = (theme: Theme) => ({
  gridContainer: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#F4F2FA',
  },
  imageBox: {
    height: '100vh',
    width: '50vw',
    padding: '30px 0px 30px 30px',
  },
  title: {
    top: '41vh',
    left: '9vw',
    width: '30vw',
    position: 'absolute',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '100px',
    lineHeight: '150px',
    letterSpacing: '5px',
    color: '#F4F2FA',
  },
  line: {
    position: 'absolute',
    right: '25vw',
    top: 0,
    width: '2px',
    height: '25vh',
    backgroundColor: theme.palette.primary.main,
  },
  gridLogin: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formWrapper: {
    width: '30vw',

    display: 'flex',
    flexDirection: 'column',
  },
  boxTitle: {
    height: '8vh',
    marginBottom: '5vh',
  },
  boxTypographie: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '25vh',
  },
  typoCode: {
    border: 'none',
    padding: '0',
    '& fieldset': { border: 'none' },
    width: '25vw',
  },
  typoCodeInputProps: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '8px',
    height: '22px',
    padding: '15.5px',
  },
  typoCodeInputLabelsProps: {
    backgroundColor: 'white',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0px',
    color: '#1E3440',
    textAlign: 'center',
    width: '200px',
    height: '23px',
  },
  typoPassword: {
    padding: '0',
    width: '25vw',
    borderRadius: '8px',
    '&:focus, &:valid': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    '& fieldset': { borderRadius: '8px' },
  },
  typoPasswordInputProps: {
    padding: '15.5px',
    height: '22px',
    borderRadius: '8px',
  },
  typoPasswordInputLabelsProps: {
    '&.MuiInputLabel-shrink': {
      backgroundColor: 'white',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '21px',
      letterSpacing: '0px',
      color: '#1E3440',
      textAlign: 'center',
      width: '120px',
      height: '23px',
      '& + fieldset': {
        fontWeight: 'normal',
        fontSize: '15px',
        lineHeight: '23px',
        letterSpacing: '0px',
        color: '#A3A3A3',
      },
    },
  },
  button: {
    height: '57px',
    width: '400px !important',
  },
  buttonLink: {
    height: '57px',
    width: '10vw',
  },
  buttonTypo: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
  },
});
