import { lazy } from 'react';
import { RoutesDefinition } from '../routes';
import { PERMISSIONS } from '../../utils/Lists/roles-permissions-list';

export const valueListRoutes: RoutesDefinition[] = [
  {
    name: 'Listes de valeurs',
    component: lazy(() => import('../../components/AdminInterface/ValueList/index')),
    permissions: [PERMISSIONS.VALUE_LIST_LIST],
    path: '/value-list',
  },
];
