import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ClassIcon from '@mui/icons-material/Class';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import WatchIcon from '@mui/icons-material/Watch';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { DrawerMenu } from '../DashboardCardLists/menu-list';
import { PERMISSIONS } from '../roles-permissions-list';

export const DRAWER_TIME_TRACKING_LIST: DrawerMenu[] = [
  {
    id: 'time-tracking',
    path: '/time-tracking/list',
    label: 'Temps opérateurs',
    subLabel: 'Liste des temps',
    icon: AccessTimeIcon,
    color: '',
    permissions: [PERMISSIONS.OPERATOR_TIME],
  },
  {
    id: 'time-tracking',
    path: '/time-tracking/real-time',
    label: 'Temps réels',
    subLabel: 'Suivi en temps réel',
    icon: WatchIcon,
    color: '',
    permissions: [PERMISSIONS.OPERATOR_REAL_TIME],
  },
  {
    id: 'time-tracking',
    path: '/time-tracking/flash-barcode',
    label: 'Scan CB',
    subLabel: 'Flasher code barre',
    icon: QrCodeScannerIcon,
    color: '',
    permissions: [PERMISSIONS.OPERATOR_FLASH_BARCODE],
  },
  {
    id: 'time-tracking-section',
    path: '/time-tracking/section',
    label: 'Sections',
    subLabel: 'section',
    icon: ClassIcon,
    color: '',
    permissions: [PERMISSIONS.SECTION_TIME],
  },
  {
    id: 'time-tracking-indicators',
    path: '/time-tracking/indicator',
    label: 'Indicateurs',
    subLabel: 'indicateurs',
    icon: EqualizerIcon,
    color: '',
    permissions: [PERMISSIONS.TIME_INDICATOR],
  },
];
