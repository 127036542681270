import { lazy } from 'react';
import { RoutesDefinition } from '../routes';
import { PERMISSIONS } from '../../utils/Lists/roles-permissions-list';

export const nomenclaturesRoutes: RoutesDefinition[] = [
  {
    name: 'Nomenc Root',
    component: lazy(() => import('../../components/UI/DrawerRootEntry')),
    permissions: [],
    path: '/nomenc',
  },
  {
    name: 'Liste des Nomenclatures formattées',
    component: lazy(() => import('../../components/AdminInterface/Nomenclatures/ListNomenclaturesFormatted')),
    permissions: [PERMISSIONS.NOMENCLATURE_FORMATTED_LIST],
    path: '/nomenc-formatted/list',
  },
  {
    name: 'Liste Coefficients Nomenclatures',
    component: lazy(() => import('../../components/AdminInterface/Nomenclatures/ListNomenclaturesCoef')),
    permissions: [PERMISSIONS.NOMENCLATURE_COEF_LIST],
    path: '/nomenc-coef/list',
  },
  {
    name: 'Paramètres',
    component: lazy(() => import('../../components/AdminInterface/Nomenclatures/ConfNomenclatures')),
    permissions: [PERMISSIONS.NOMENCLATURE_CONF],
    path: '/nomenc-coef/params',
  },
  {
    name: 'Chargement',
    component: lazy(() => import('../../components/AdminInterface/Nomenclatures/UploadNomenclatures')),
    permissions: [PERMISSIONS.NOMENCLATURE_UPLOAD],
    path: '/nomenc-coef/upload',
  },
];
