import { createAsyncThunk } from '@reduxjs/toolkit';
import { UiSlice } from '../ui/ui.slice';
import axiosApiInstance from '../../config/axios';
import { ErrorApiModel } from '../../config/error-api.model';
import { CreateOperatorDto, UpdateOperatorDto } from '../user/user';

const { displayAlert, displayLoader } = UiSlice.actions;

const url: string = process.env.REACT_APP_API_BASE_URI || '/api';

export const FETCH_OPERATORS = createAsyncThunk(
  'user/FETCH_OPERATORS',
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.get(
        `${url}/users/operator`,
      );
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const UPDATE_OPERATOR = createAsyncThunk(
  'user/UPDATE_OPERATOR',
  async (data: UpdateOperatorDto, thunkApi) => {
    try {
      const result = await axiosApiInstance.patch(`${url}/users/operator`, {
        ...data,
      });
      thunkApi.dispatch(
        displayAlert({
          message: 'Utilisateur modifié avec succès',
          color: 'success',
          timeout: 3000,
        }),
      );
      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 5000,
        }),
      );
      throw error;
    }
  },
);

export const CREATE_OPERATOR = createAsyncThunk(
  'user/CREATE_OPERATOR',
  async (data: CreateOperatorDto, thunkApi) => {
    try {
      const result = await axiosApiInstance.post(`${url}/users/operator`, {
        ...data,
      });
      thunkApi.dispatch(
        displayAlert({
          message:
            'Opérateur créé avec succès - Mot de passe 12345',
          color: 'success',
          timeout: 3000,
        }),
      );
      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 5000,
        }),
      );
      throw error;
    }
  },
);
