import ListIcon from '@mui/icons-material/List';
import SettingsIcon from '@mui/icons-material/Settings';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DrawerMenu } from '../DashboardCardLists/menu-list';
import { PERMISSIONS } from '../roles-permissions-list';

export const DRAWER_ARTICLES_LIST: DrawerMenu[] = [
  {
    id: 'articles',
    path: '/articles/list',
    label: 'ARTICLES',
    subLabel: 'Liste des articles',
    icon: ListIcon,
    color: '',
    permissions: [PERMISSIONS.ARTICLE_LIST],
  },
  {
    id: 'paramètres',
    path: '/articles/params',
    label: 'CONFIGURATION',
    subLabel: 'paramètres',
    icon: SettingsIcon,
    color: '',
    permissions: [PERMISSIONS.ARTICLE_CONF],
  },
  {
    id: 'upload',
    path: '/articles/upload',
    label: 'CHARGEMENT',
    subLabel: 'upload',
    icon: CloudUploadIcon,
    color: '',
    permissions: [PERMISSIONS.ARTICLE_UPLOAD],
  },
];
