import { createSlice } from '@reduxjs/toolkit';
import { CLEAN_ALL_STATES } from '../global-actions';
import {
  ADD_ARTICLE_TO_STOCK_LOCATION,
  CREATE_STOCK_LOCATION,  
  DELETE_ARTICLE_FROM_STOCK_LOCATION, 
  FETCH_ALL_STOCK_LOCATION, FETCH_ARTICLES_STOCK_BY_DATE_RANGE, 
  UPDATE_STOCK_LOCATION, 
} from './stock.thunk';
import { StockState } from './stock';

const initialState: StockState = {
  list: [],
  articleList: [],
  isLoading: false,
};

export const stockSlice = createSlice({
  name: 'stockSlice',
  initialState,
  reducers: {
    cleanStocks(state) {
      return {
        ...state,
        ...initialState,
      };
    },
    cleanArticlesStocks(state) {
      return {
        ...state,
        articleList: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FETCH_ALL_STOCK_LOCATION.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FETCH_ALL_STOCK_LOCATION.fulfilled, (state, action) => ({
      ...state, list: action.payload, isLoading: false,
    }));
    builder.addCase(FETCH_ALL_STOCK_LOCATION.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(CREATE_STOCK_LOCATION.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CREATE_STOCK_LOCATION.fulfilled, (state, action) => {
      state.list.push(action.payload);
      state.isLoading = false;
    });
    builder.addCase(CREATE_STOCK_LOCATION.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(UPDATE_STOCK_LOCATION.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(UPDATE_STOCK_LOCATION.fulfilled, (state, action) => {
      const index = state.list.findIndex((stock) => stock._id === action.payload._id);
    
      if (index !== -1) {
        state.list[index] = action.payload;
      }
    
      state.isLoading = false;
    });
    builder.addCase(UPDATE_STOCK_LOCATION.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(ADD_ARTICLE_TO_STOCK_LOCATION.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(ADD_ARTICLE_TO_STOCK_LOCATION.fulfilled, (state, action) => {
      state.articleList.push(action.payload);
      state.isLoading = false;
    });
    builder.addCase(ADD_ARTICLE_TO_STOCK_LOCATION.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(DELETE_ARTICLE_FROM_STOCK_LOCATION.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(DELETE_ARTICLE_FROM_STOCK_LOCATION.fulfilled, (state, action) => {
      state.articleList = state.articleList.filter((article) => article._id !== action.payload._id);
      state.isLoading = false;
    });
    builder.addCase(DELETE_ARTICLE_FROM_STOCK_LOCATION.rejected, (state) => {
      state.isLoading = false;
    });
    
    builder.addCase(FETCH_ARTICLES_STOCK_BY_DATE_RANGE.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FETCH_ARTICLES_STOCK_BY_DATE_RANGE.fulfilled, (state, action) => ({
      ...state, articleList: action.payload, isLoading: false,
    }));
    builder.addCase(FETCH_ARTICLES_STOCK_BY_DATE_RANGE.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const StockActions = {
  ...stockSlice.actions,
};
