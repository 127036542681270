import { createSlice } from '@reduxjs/toolkit';
import { CLEAN_ALL_STATES } from '../global-actions';
import {
  DELETE_NOMENC_COEF,
  FETCH_ALL_NOMENC_COEF,
  FETCH_ALL_NOMENC_FORMATTED,
  UPDATE_NOMENC_COEF,
  FETCH_FULL_BOM,
  CREATE_NOMENC_COEF,
} from './nomenc.thunk';
import { NomencState } from './nomenc';

const initialState: NomencState = {
  listCoef: [],
  listFormatted: [],
  currentFormatted: null,
  isLoading: false,
};

export const nomencSlice = createSlice({
  name: 'nomencSlice',
  initialState,
  reducers: {
    cleanNomenc(state) {
      return {
        ...state,
        ...initialState,
      };
    },
    cleanCurrentFormatted(state) {
      return {
        ...state,
        currentFormatted: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FETCH_ALL_NOMENC_COEF.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FETCH_ALL_NOMENC_COEF.fulfilled, (state, action) => ({
      ...state, listCoef: action.payload, isLoading: false,
    }));
    builder.addCase(FETCH_ALL_NOMENC_COEF.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(FETCH_ALL_NOMENC_FORMATTED.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FETCH_ALL_NOMENC_FORMATTED.fulfilled, (state, action) => ({
      ...state, listFormatted: action.payload, isLoading: false,
    }));
    builder.addCase(FETCH_ALL_NOMENC_FORMATTED.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(FETCH_FULL_BOM.fulfilled, (state, action) => ({
      ...state, currentFormatted: action.payload,
    }));
    builder.addCase(UPDATE_NOMENC_COEF.fulfilled, (state, action) => {
      state.listCoef = state.listCoef.map((rowItem) => (rowItem._id === action.payload._id
        ? action.payload : rowItem));
      state.isLoading = false;
    });
    builder.addCase(DELETE_NOMENC_COEF.fulfilled, (state, action) => {
      state.listCoef = state.listCoef.filter((rowItem) => (rowItem._id !== action.payload._id));
      state.isLoading = false;
    });
    builder.addCase(CREATE_NOMENC_COEF.fulfilled, (state, action) => {
      state.listCoef.push(action.payload.nomencCoef);
      const existingItemIndex = state.listFormatted.findIndex(
        (rowItem) => rowItem._id === action.payload.nomencFormatted._id,
      );
      if (existingItemIndex !== -1) {
        state.listFormatted[existingItemIndex] = action.payload.nomencFormatted;
      } else {
        state.listFormatted.push(action.payload.nomencFormatted);
      }
    });
    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const NomencActions = {
  ...nomencSlice.actions,
};
