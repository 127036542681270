import { createSlice } from '@reduxjs/toolkit';
import { CLEAN_ALL_STATES } from '../global-actions';
import { ProductionLineState } from './production-line';
import { FETCH_ALL_PRODUCTION_LINES, CREATE_PRODUCTION_LINE, UPDATE_PRODUCTION_LINE } from './production-line.thunk';

const initialState: ProductionLineState = {
  list: [],
  isLoading: false,
};

export const productionLineSlice = createSlice({
  name: 'ProductionLineSlice',
  initialState,
  reducers: {
    cleanProductionLines(state) {
      return {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FETCH_ALL_PRODUCTION_LINES.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FETCH_ALL_PRODUCTION_LINES.fulfilled, (state, action) => ({
      ...state, list: action.payload, isLoading: false,
    }));
    builder.addCase(FETCH_ALL_PRODUCTION_LINES.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CREATE_PRODUCTION_LINE.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CREATE_PRODUCTION_LINE.fulfilled, (state, action) => {
      state.list.push(action.payload);
      state.isLoading = false;
    });
    builder.addCase(CREATE_PRODUCTION_LINE.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(UPDATE_PRODUCTION_LINE.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(UPDATE_PRODUCTION_LINE.fulfilled, (state, action) => {
      state.list = state.list.map((productionLineItem) => (productionLineItem._id === action.payload._id
        ? action.payload : productionLineItem));
      state.isLoading = false;
    });
    builder.addCase(UPDATE_PRODUCTION_LINE.rejected, (state) => {
      state.isLoading = false;
    }); 
    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const { cleanProductionLines } = productionLineSlice.actions;

export const productionLineActions = {
  ...productionLineSlice.actions,
};
