import React from 'react';
import { Typography } from '@mui/material';
import { IProps } from './custom-typography';

const CustomTypography: React.FC<IProps> = ({
  title = '',
  variant = 'subtitle1',
  color = 'inherit',
  align = 'center',
  noWrap = false,
  marginRight = false,
  noVerticalMargin = false,
  isItalic = false,
  isBold = false,
  display = '',
  sx = {},
  botGutter = undefined,
  hidden = false,
}) => (
  <Typography
    variant={variant}
    color={color}
    align={align}
    noWrap={noWrap}
    display={display}
    sx={{
      fontWeight: isBold ? 'bold' : undefined,
      fontStyle: isItalic ? 'italic' : undefined,
      width: noVerticalMargin ? 'auto' : '100%',
      mr: marginRight ? '6px' : undefined,
      mb: botGutter || undefined,
      display: hidden ? 'none' : undefined,
      ...sx,
    }}
  >
    {title}
  </Typography>
);
export default CustomTypography;
