import { lazy } from 'react';
import { RoutesDefinition } from '../routes';
import { PERMISSIONS } from '../../utils/Lists/roles-permissions-list';

export const productionReferentialRoutes: RoutesDefinition[] = [
  {
    name: 'Referential Root',
    component: lazy(() => import('../../components/UI/DrawerRootEntry')),
    permissions: [],
    path: '/production-referential',
  },
  {
    name: 'Liste Opérations',
    component: lazy(() => import('../../components/AdminInterface/ProductionReferential/Operations')),
    permissions: [PERMISSIONS.OPERATION],
    path: '/production-referential/operations',
  },
  {
    name: 'Liste Gammes',
    component: lazy(() => import('../../components/AdminInterface/ProductionReferential/Routings')),
    permissions: [PERMISSIONS.ROUTING],
    path: '/production-referential/routings',
  },
  {
    name: 'Liste Lignes de production',
    component: lazy(() => import('../../components/AdminInterface/ProductionReferential/ProductionLines')),
    permissions: [PERMISSIONS.PRODUCTION_LINE],
    path: '/production-referential/production-lines',
  },
  {
    name: 'Liste Unités',
    component: lazy(() => import('../../components/AdminInterface/ProductionReferential/Units')),
    permissions: [PERMISSIONS.UNIT],
    path: '/production-referential/units',
  },
];
