import { lazy } from 'react';
import { RoutesDefinition } from '../routes';

export const adminRoutes: RoutesDefinition[] = [
  {
    name: 'Admin Dashboard',
    component: lazy(() => import('../../components/AdminInterface')),
    permissions: [],
    path: '/admin',
  },
];
