import ListIcon from '@mui/icons-material/List';
import SettingsIcon from '@mui/icons-material/Settings';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DrawerMenu } from '../DashboardCardLists/menu-list';
import { PERMISSIONS } from '../roles-permissions-list';

export const STOCK_LOCATION_LIST: DrawerMenu[] = [
  {
    id: 'stock',
    path: '/stock/list',
    label: 'STOCK',
    subLabel: 'Quantités en stock',
    icon: ListIcon,
    color: '',
    permissions: [PERMISSIONS.STOCK_LIST],
  },
  {
    id: 'paramètres',
    path: '/stock/params',
    label: 'CONFIGURATION',
    subLabel: 'paramètres',
    icon: SettingsIcon,
    color: '',
    permissions: [PERMISSIONS.STOCK_CONF],
  },
  {
    id: 'upload',
    path: '/stock/upload',
    label: 'CHARGEMENT',
    subLabel: 'upload',
    icon: CloudUploadIcon,
    color: '',
    permissions: [PERMISSIONS.STOCK_UPLOAD],
  },
  
];
