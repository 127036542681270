export const usersInitialState = {
  list: [],
  isLoading: false,
  current: {
    _id: '',
    organisation: [{
      _id: '',
      code: '',
      description: '',
      cluster: 0,
    }],
    currentOrganisation: {
      _id: '',
      code: '',
      description: '',
      cluster: 0,
    },
    name: '',
    surname: '',
    email: '',
    code: '',
    roles: [],
    permissions: [],
  },
};
