import React, { useEffect, useRef } from 'react';
import { Grid, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store';
import { getSx } from './sx';
import { AuthLayoutProps } from './auth-layout';
import LoginImageBackground from '../UI/LoginImageBackground';

export const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  const theme = useTheme();
  const sx = getSx(theme);

  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.current);
  const shouldMount = useRef(true);

  useEffect(() => {
    if (shouldMount.current && user?._id) {
      if (user.roles.includes('WORKSTATION')) {
        navigate('/workstation');
      }
      if (!user.roles.includes('WORKSTATION')) {
        navigate('/admin');
      }

      shouldMount.current = false;
    }
  }, [user]);

  return (
    <Grid container sx={sx.gridContainer}>
      <Grid
        item
        xs={6}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <LoginImageBackground />
      </Grid>
      <Grid item xs={6} sx={sx.gridLogin}>
        <Box sx={sx.line} />
        <Box sx={sx.formWrapper}>{children}</Box>
      </Grid>
    </Grid>
  );
};
