const generateTabId = () => `${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

const getTabId = () => {
  let tabId = sessionStorage.getItem('tabId');
  if (!tabId) {
    tabId = generateTabId();
    sessionStorage.setItem('tabId', tabId);
  }
  return tabId;
};

export const getToken = () => {
  const tabId = getTabId();
  return sessionStorage.getItem(`auth-token-planitrack_${tabId}`);
};

export const saveToken = (token: string) => {
  const tabId = getTabId();
  sessionStorage.setItem(`auth-token-planitrack_${tabId}`, token);
};

export const deleteToken = () => {
  const tabId = getTabId();
  sessionStorage.removeItem(`auth-token-planitrack_${tabId}`);
};
