import { lazy } from 'react';
import { RoutesDefinition } from '../routes';
import { PERMISSIONS } from '../../utils/Lists/roles-permissions-list';

export const forecastsRoutes: RoutesDefinition[] = [
  {
    name: 'Forecast Root',
    component: lazy(() => import('../../components/UI/DrawerRootEntry')),
    permissions: [],
    path: '/forecasts',
  },
  {
    name: 'Liste Prévisions',
    component: lazy(() => import('../../components/AdminInterface/Forecasts/ListForecasts')),
    permissions: [PERMISSIONS.FORECAST_LIST],
    path: '/forecasts/list',
  },
  {
    name: 'Paramètres',
    component: lazy(() => import('../../components/AdminInterface/Forecasts/ConfForecasts')),
    permissions: [PERMISSIONS.FORECAST_CONF],
    path: '/forecasts/params',
  },
  {
    name: 'Chargement',
    component: lazy(() => import('../../components/AdminInterface/Forecasts/UploadForecasts')),
    permissions: [PERMISSIONS.FORECAST_UPLOAD],
    path: '/forecasts/upload',
  },
];
