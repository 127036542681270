import { createAsyncThunk } from '@reduxjs/toolkit';
import { UiSlice } from '../ui/ui.slice';
import axiosApiInstance from '../../config/axios';
import { ErrorApiModel } from '../../config/error-api.model';
import { AddValueInList, ValueList } from './value-list';

const { displayAlert, displayLoader } = UiSlice.actions;

const url: string = process.env.REACT_APP_API_BASE_URI || '/api';

export const FETCH_VALUE_LISTS = createAsyncThunk(
  'value-list/FETCH_VALUE_LISTS',
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.get(
        `${url}/value-list`,
      );
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const FETCH_FILTERED_VALUE_LISTS = createAsyncThunk(
  'value-list/FETCH_FILTERED_VALUE_LISTS',
  async (valueListIds: string[], thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const queryParams = valueListIds.map((id) => `ids=${encodeURIComponent(id)}`).join('&');
      const result = await axiosApiInstance.get(
        `${url}/value-list/filter?${queryParams}`,
      );
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const CREATE_VALUE_LIST = createAsyncThunk(
  'value-list/CREATE_VALUE_LIST',
  async (data: ValueList, thunkApi) => {
    try {
      const result = await axiosApiInstance.post(`${url}/value-list`, {
        ...data,
      });
      thunkApi.dispatch(
        displayAlert({
          message:
            'Liste créé avec succès - Vous pouvez ajouter des valeurs',
          color: 'success',
          timeout: 3000,
        }),
      );
      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 5000,
        }),
      );
      throw error;
    }
  },
);

export const UPDATE_VALUE_LIST = createAsyncThunk(
  'value-list/UPDATE_VALUE_LIST',
  async (payload: ValueList, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.patch(`${url}/value-list`, payload);
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Ligne modifiée avec succès',
          color: 'success',
          timeout: 6000,
        }),
      );

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const CREATE_VALUE_IN_LIST = createAsyncThunk(
  'value-list/CREATE_VALUE_IN_LIST',
  async (data: AddValueInList, thunkApi) => {
    try {
      const result = await axiosApiInstance.post(`${url}/value-list/add-value`, {
        ...data,
      });
      thunkApi.dispatch(
        displayAlert({
          message:
            'Valeur ajoutée avec succès',
          color: 'success',
          timeout: 3000,
        }),
      );
      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 5000,
        }),
      );
      throw error;
    }
  },
);
