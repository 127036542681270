import { createAsyncThunk } from '@reduxjs/toolkit';
import { UiSlice } from '../ui/ui.slice';
import axiosApiInstance from '../../config/axios';
import { ErrorApiModel } from '../../config/error-api.model';
import { TimeTrackerReportDto } from './time-tracker';

const { displayAlert, displayLoader } = UiSlice.actions;

const url: string = process.env.REACT_APP_API_BASE_URI || '/api';

export const FETCH_ACTIVITY_TIME_REPORT = createAsyncThunk(
  'time-tracker/FETCH_ACTIVITY_TIME_REPORT',
  async (payload: TimeTrackerReportDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(`${url}/time-tracker/activity/get-report`, payload);
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);
