import { createSlice } from '@reduxjs/toolkit';
import { Users } from './user';
import {
  CHECK_LOG_USER, LOG_USER, FETCH_ADMIN_USERS,
  CREATE_ADMIN_USER,
  UPDATE_ADMIN_USER,
  DELETE_USER,
  CHANGE_CURRENT_ORGANISATION,
} from './user.thunk';
import { CLEAN_ALL_STATES } from '../global-actions';
import { deleteToken } from '../../utils/local-storage';
import { usersInitialState } from './initial-state';

const initialState: Users = usersInitialState;

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUser: (state: any, action: any) => {
      state.current = { ...action.payload };
    },
    cleanUser: () => {
      deleteToken();
      return initialState;
    },
    cleanUsersList: (state:Users) => ({
      ...state,
      list: [],
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(LOG_USER.fulfilled, (state, action) => ({
      ...state, current: action.payload, isLoading: false,
    }));
    builder.addCase(LOG_USER.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(LOG_USER.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CHANGE_CURRENT_ORGANISATION.fulfilled, (state, action) => ({
      ...state,
      list: state.list.map((userItem) => (userItem._id === action.payload._id ? action.payload : userItem)),
      current: {
        ...action.payload,
      },
      isLoading: false,
    }));
    builder.addCase(CHANGE_CURRENT_ORGANISATION.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CHANGE_CURRENT_ORGANISATION.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CHECK_LOG_USER.fulfilled, (state, action) => ({
      ...state, ...action.payload, isLoading: false, id: action.payload._id,
    }));
    builder.addCase(CHECK_LOG_USER.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CHECK_LOG_USER.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(FETCH_ADMIN_USERS.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FETCH_ADMIN_USERS.fulfilled, (state, action) => {
      state.list = action.payload;
      state.isLoading = false;
    });
    builder.addCase(FETCH_ADMIN_USERS.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CREATE_ADMIN_USER.fulfilled, (state, action) => {
      state.list = [...state.list, action.payload];
      state.isLoading = false;
    });
    builder.addCase(CREATE_ADMIN_USER.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CREATE_ADMIN_USER.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(UPDATE_ADMIN_USER.fulfilled, (state, action) => {
      state.list = state.list.map((user) => (user.code === action.payload.code ? action.payload : user));
      state.current = state.current._id === action.payload._id ? action.payload : state.current;
      state.isLoading = false;
    });
    builder.addCase(UPDATE_ADMIN_USER.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(UPDATE_ADMIN_USER.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(DELETE_USER.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(DELETE_USER.fulfilled, (state, action) => {
      state.list = state.list.filter((userItem) => (userItem._id !== action.payload._id));
      state.isLoading = false;
    });
    builder.addCase(DELETE_USER.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const { cleanUser, saveUser } = userSlice.actions;

export const UserActions = {
  ...userSlice.actions,
  CHECK_LOG_USER,
  LOG_USER,
};
