import { createSlice } from '@reduxjs/toolkit';
import { CLEAN_ALL_STATES } from '../global-actions';
import { ValueListState } from './value-list';
import {
  CREATE_VALUE_IN_LIST, CREATE_VALUE_LIST, FETCH_FILTERED_VALUE_LISTS, FETCH_VALUE_LISTS, UPDATE_VALUE_LIST, 
} from './value-list.thunk';

const initialState: ValueListState = {
  list: [],
  isLoading: false,
};

export const valueListSlice = createSlice({
  name: 'valueListSlice',
  initialState,
  reducers: {
    cleanValueList(state) {
      return {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FETCH_VALUE_LISTS.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FETCH_VALUE_LISTS.fulfilled, (state, action) => ({
      ...state, list: action.payload, isLoading: false,
    }));
    builder.addCase(FETCH_VALUE_LISTS.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(FETCH_FILTERED_VALUE_LISTS.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FETCH_FILTERED_VALUE_LISTS.fulfilled, (state, action) => ({
      ...state, list: action.payload, isLoading: false,
    }));
    builder.addCase(FETCH_FILTERED_VALUE_LISTS.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CREATE_VALUE_LIST.fulfilled, (state, action) => {
      state.list = [...state.list, action.payload];
      state.isLoading = false;
    });
    builder.addCase(CREATE_VALUE_LIST.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CREATE_VALUE_LIST.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(UPDATE_VALUE_LIST.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(UPDATE_VALUE_LIST.fulfilled, (state, action) => {
      state.list = state.list.map((rowItem) => (rowItem._id === action.payload._id
        ? action.payload : rowItem));
      state.isLoading = false;
    });
    builder.addCase(UPDATE_VALUE_LIST.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CREATE_VALUE_IN_LIST.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CREATE_VALUE_IN_LIST.fulfilled, (state, action) => {
      state.list = state.list.map((rowItem) => (rowItem._id === action.payload._id
        ? action.payload : rowItem));
      state.isLoading = false;
    });
    builder.addCase(CREATE_VALUE_IN_LIST.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const valueListActions = {
  ...valueListSlice.actions,
};
