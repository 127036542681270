import { lazy } from 'react';
import { RoutesDefinition } from '../routes';
import { PERMISSIONS } from '../../utils/Lists/roles-permissions-list';

export const uploadRulesRoutes: RoutesDefinition[] = [
  {
    name: 'Upload Rule Root',
    component: lazy(() => import('../../components/UI/DrawerRootEntry')),
    permissions: [],
    path: '/upload-rules',
  },
  {
    name: 'Liste Règles d\'imports',
    component: lazy(() => import('../../components/AdminInterface/Upload-Rules/UploadRuleList/index')),
    permissions: [PERMISSIONS.UPLOAD_RULE_LIST],
    path: '/upload-rules/list',
  },
  {
    name: 'Liste Règles d\'imports',
    component: lazy(() => import('../../components/AdminInterface/Upload-Rules/UploadSpecificScript/index')),
    permissions: [PERMISSIONS.SCRIPT_SPECIFIC],
    path: '/upload-rules/scripts',
  },
];
