import React, { useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { Box, CssBaseline } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../store';
import Snackbar from '../UI/GlobalSnackBar';
import { CHECK_LOG_USER } from '../../store/user/user.thunk';
import { saveUser } from '../../store/user/user.slice';
import Routing from '../../routes';
import { useAppDispatch } from '../../utils/hook/UseReduxHook';
import { getToken } from '../../utils/local-storage';
import Header from '../Header';

dayjs.extend(weekOfYear as any);

const App: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.current);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const shouldMount = useRef(true);

  useEffect(() => {
    async function checkUser(token: string) {
      if (shouldMount.current) {
        const checkedUser = await dispatch(CHECK_LOG_USER(token));
        if (checkedUser?.payload) {
          dispatch(saveUser(checkedUser.payload));
        }
      }
    }

    if (shouldMount.current) {
      const token = getToken();

      if (token && !user._id) {
        checkUser(token);
      }

      if (
        !token
        && !['/reset-password', '/forgot-password'].includes(location.pathname)
      ) {
        navigate('/login');
      }

      shouldMount.current = false;
    }
  }, []);

  return (
    <>
      <CssBaseline />

      <Snackbar />

      {/* Header */}
      {user?._id && <Header />}

      {/* Routes */}
      <main>
        <Box sx={{ marginTop: user?._id ? '64px' : '0px' }}>
          <Routing user={user} key={user._id} />
        </Box>
      </main>
    </>
  );
};

export default App;
