import FactoryIcon from '@mui/icons-material/Factory';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import BalanceIcon from '@mui/icons-material/Balance';
import { DrawerMenu } from '../DashboardCardLists/menu-list';
import { PERMISSIONS } from '../roles-permissions-list';

export const DRAWER_PRODUCTION_REFERENTIAL: DrawerMenu[] = [
  {
    id: 'operations',
    path: '/production-referential/operations',
    label: 'OPERATIONS',
    subLabel: 'Liste des opérations',
    icon: FactoryIcon,
    color: '',
    permissions: [PERMISSIONS.OPERATION],
  },
  {
    id: 'routings',
    path: '/production-referential/routings',
    label: 'GAMMES DE FAB',
    subLabel: 'Liste des gammes',
    icon: FactCheckIcon,
    color: '',
    permissions: [PERMISSIONS.ROUTING],
  },
  {
    id: 'production-lines',
    path: '/production-referential/production-lines',
    label: 'LIGNES DE PROD',
    subLabel: 'Liste des lignes de production',
    icon: PrecisionManufacturingIcon,
    color: '',
    permissions: [PERMISSIONS.PRODUCTION_LINE],
  },
  {
    id: 'units',
    path: '/production-referential/units',
    label: 'UNITES',
    subLabel: 'Liste des unités',
    icon: BalanceIcon,
    color: '',
    permissions: [PERMISSIONS.UNIT],
  },
];
