import { Theme } from '@mui/material';

export const getSx = (theme: Theme) => ({
  appBar: {
    zIndex: () => theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.primary.light,
    padding: '0',
  },
  titleWrapper: {
    padding: '0 1rem',
    borderRight: '1px solid white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '-24px',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '27px',
    letterSpacing: '0.45px',
    margin: 0,
    padding: 0,
  },
  toolBarContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});
