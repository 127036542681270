import { createSlice } from '@reduxjs/toolkit';
import { EditorState } from './editor';
import { CLEAN_ALL_STATES } from '../global-actions';

const initialState: EditorState = {
  errors: [],
};

export const editorSlice = createSlice({
  name: 'editorSlice',
  initialState,
  reducers: {
    registerError: (state, action) => {
      state.errors.push(action.payload);
    },
    clearErrors: (state) => {
      state.errors = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(CLEAN_ALL_STATES, () => ({
      ...initialState,
    }));
  },

});

export const EditorActions = {
  ...editorSlice.actions,
};
