import { createSlice } from '@reduxjs/toolkit';
import { CLEAN_ALL_STATES } from '../global-actions';
import {
  CREATE_ARTICLE,
  DELETE_ARTICLE, FETCH_ALL_ARTICLES, UPDATE_ARTICLE,
} from './article.thunk';
import { ArticleState } from './article';

const initialState: ArticleState = {
  list: [],
  isLoading: false,
};

export const articleSlice = createSlice({
  name: 'articleSlice',
  initialState,
  reducers: {
    cleanArticles(state) {
      return {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FETCH_ALL_ARTICLES.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FETCH_ALL_ARTICLES.fulfilled, (state, action) => ({
      ...state, list: action.payload, isLoading: false,
    }));
    builder.addCase(FETCH_ALL_ARTICLES.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(UPDATE_ARTICLE.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(UPDATE_ARTICLE.fulfilled, (state, action) => {
      state.list = state.list.map((rowItem) => (rowItem._id === action.payload._id
        ? action.payload : rowItem));
      state.isLoading = false;
    });
    builder.addCase(UPDATE_ARTICLE.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(DELETE_ARTICLE.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(DELETE_ARTICLE.fulfilled, (state, action) => {
      state.list = state.list.filter((rowItem) => (rowItem._id !== action.payload._id));
      state.isLoading = false;
    });
    builder.addCase(DELETE_ARTICLE.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CREATE_ARTICLE.fulfilled, (state, action) => {
      state.list.push(action.payload);
      state.isLoading = false;
    });
    builder.addCase(CREATE_ARTICLE.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CREATE_ARTICLE.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const ArticleActions = {
  ...articleSlice.actions,
};
