import React, { useRef, useState } from 'react';
import StoreIcon from '@mui/icons-material/Store';
import { Box } from '@mui/system';
import { useTheme } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { OrganisationLogoProps } from './organisation';
import CustomTypography from '../../UI/CustomComponents/CustomTypography';
import { getSx } from './sx';
import OrganisationListMenu from '../Menus/OrganisationListMenu/OrganisationListMenu';

const OrganisationLogo: React.FC<OrganisationLogoProps> = ({ user }) => {
  const theme = useTheme();
  const sx = getSx(theme);
  const organisationListMenu = useRef(null);
  const [organisationListOpen, setOrganisationListOpen] = useState(false);

  return (
    <>
    
      <Box sx={sx.outerBox} onClick={() => setOrganisationListOpen(true)} ref={organisationListMenu}>
        <Box sx={sx.iconBox}>
          <StoreIcon sx={sx.icon} />
        </Box>
        <Box sx={sx.typoBox}>
          <CustomTypography
            title={`${user?.currentOrganisation?.code?.toUpperCase()}`}
            variant="h6"
            sx={sx.workstationTitle}
          />
        </Box>
        <ArrowDropDownIcon />
      </Box>

      <OrganisationListMenu
        anchorEl={organisationListMenu.current}
        isOpen={organisationListOpen}
        onClose={() => setOrganisationListOpen(false)}
        user={user}
      />
    </>
  );
};

export default OrganisationLogo;
