import { lazy } from 'react';
import { RoutesDefinition } from '../routes';
import ForgotPassword from '../../components/AuthLayout/ForgotPassword';
import ResetPasswordPage from '../../components/AuthLayout/ResetPasswordPage';

export const publicRoutes: RoutesDefinition[] = [
  {
    name: 'Connexion',
    component: lazy(() => import('../../components/AuthLayout/Login')),
    permissions: [],
    path: '/login',
  },
  {
    name: 'Mot de passe oublié',
    component: ForgotPassword,
    permissions: [],
    path: '/forgot-password',
  },
  {
    name: 'Changement de mot de passe',
    component: ResetPasswordPage,
    permissions: [],
    path: '/reset-password',
  },
];
