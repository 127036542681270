import { createAsyncThunk } from '@reduxjs/toolkit';
import { UiSlice } from '../ui/ui.slice';
import axiosApiInstance from '../../config/axios';
import { ErrorApiModel } from '../../config/error-api.model';
import { CorrespondenceTable } from './correspondence-table';

const { displayAlert, displayLoader } = UiSlice.actions;

const url: string = process.env.REACT_APP_API_BASE_URI || '/api';

export const FETCH_CORRESPONDENCE_TABLES = createAsyncThunk(
  'correspondence-table/FETCH_CORRESPONDENCE_TABLES',
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.get(
        `${url}/correspondence-table`,
      );
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const UPDATE_CORRESPONDENCE_TABLE = createAsyncThunk(
  'correspondence-table/UPDATE_CORRESPONDENCE_TABLE',
  async (payload: CorrespondenceTable, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.patch(`${url}/correspondence-table`, payload);
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Ligne modifiée avec succès',
          color: 'success',
          timeout: 6000,
        }),
      );

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const CREATE_CORRESPONDENCE_TABLE = createAsyncThunk(
  'correspondence-table/CREATE_CORRESPONDENCE_TABLE',
  async (data: CorrespondenceTable, thunkApi) => {
    try {
      const result = await axiosApiInstance.post(`${url}/correspondence-table`, {
        ...data,
      });
      thunkApi.dispatch(
        displayAlert({
          message:
            'Table créé avec succès - Vous pouvez ajouter des correspondances',
          color: 'success',
          timeout: 3000,
        }),
      );
      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 5000,
        }),
      );
      throw error;
    }
  },
);

export const CREATE_MAPPING_OBJECT = createAsyncThunk(
  'correspondence-table/CREATE_MAPPING_OBJECT',
  async (data: CorrespondenceTable, thunkApi) => {
    try {
      const result = await axiosApiInstance.post(`${url}/correspondence-table/mapping-object`, {
        ...data,
      });
      thunkApi.dispatch(
        displayAlert({
          message:
            'Ligne créée avec succès - Vous pouvez désormais la modifier',
          color: 'success',
          timeout: 3000,
        }),
      );
      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 5000,
        }),
      );
      throw error;
    }
  },
);
