import { createSlice } from '@reduxjs/toolkit';
import { CLEAN_ALL_STATES } from '../global-actions';
import {
  GET_COLLECTIONS_DOCUMENTS, 
} from './collection.thunk';
import { CollectionState } from './collection';

const initialState: CollectionState = {
  list: [],
  isLoading: false,
};

export const collectionSlice = createSlice({
  name: 'collectionSlice',
  initialState,
  reducers: {
    cleanCollections(state) {
      return {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GET_COLLECTIONS_DOCUMENTS.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(GET_COLLECTIONS_DOCUMENTS.fulfilled, (state, action) => {
      state.list = action.payload;
      state.isLoading = false;
    });
    builder.addCase(GET_COLLECTIONS_DOCUMENTS.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const { cleanCollections } = collectionSlice.actions;

export const CollectionActions = {
  ...collectionSlice.actions,
};
