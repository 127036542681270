import ListIcon from '@mui/icons-material/List';
import { DrawerMenu } from '../DashboardCardLists/menu-list';
import { PERMISSIONS } from '../roles-permissions-list';

export const DRAWER_CORRESPONDENCE_TABLE: DrawerMenu[] = [
  {
    id: 'correspondence-table',
    path: '/correspondence-table',
    label: 'TABLES DE CORRESPONDENCE',
    subLabel: 'Liste des tables',
    icon: ListIcon,
    color: '',
    permissions: [PERMISSIONS.MAPPING_TABLE_LIST],
  },
];
