import { object, string, ref } from 'yup';

export const resetPasswordSchema = object().shape({
  password: string()
    .required('Le mot de passe est requis')
    .min(6, 'Le mot de passe doit contenir au moins 6 caractères'),
  confirmPassword: string()
    .oneOf([ref('password'), undefined], 'Les mots de passe ne correspondent pas')
    .required('La confirmation du mot de passe est requise'),
});
