import { lazy } from 'react';
import { RoutesDefinition } from '../routes';
import { PERMISSIONS } from '../../utils/Lists/roles-permissions-list';

export const organisationsAndUsersRoutes: RoutesDefinition[] = [
  {
    name: 'Organisation Root',
    component: lazy(() => import('../../components/UI/DrawerRootEntry')),
    permissions: [],
    path: '/organisations',
  },
  {
    name: 'Liste Organisations',
    component: lazy(() => import('../../components/AdminInterface/OrganisationsAndUsers/ListOrganisations')),
    permissions: [PERMISSIONS.ORGANISATION_LIST],
    path: '/organisations/list',
  },
  {
    name: 'Liste Utilisateurs',
    component: lazy(() => import('../../components/AdminInterface/OrganisationsAndUsers/ListUsers')),
    permissions: [PERMISSIONS.USER_LIST],
    path: '/users/list',
  },
  {
    name: 'Liste Opérateurs',
    component: lazy(() => import('../../components/AdminInterface/OrganisationsAndUsers/ListOperators')),
    permissions: [PERMISSIONS.OPERATOR_LIST],
    path: '/operators/list',
  },
];
