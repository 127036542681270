import { lazy } from 'react';
import { RoutesDefinition } from '../routes';
import { PERMISSIONS } from '../../utils/Lists/roles-permissions-list';

export const productionOrdersRoutes: RoutesDefinition[] = [
  {
    name: 'Production Order Root',
    component: lazy(() => import('../../components/UI/DrawerRootEntry')),
    permissions: [],
    path: '/production-orders',
  },
  {
    name: 'Liste OF',
    component: lazy(() => import('../../components/AdminInterface/ProductionOrder/ListProductionOrder')),
    permissions: [PERMISSIONS.PRODUCTION_ORDER_LIST],
    path: '/production-orders/list',
  },
  {
    name: 'Code-barres Opération',
    component: lazy(() => import('../../components/AdminInterface/ProductionOrder/OperationBarcodeList')),
    permissions: [PERMISSIONS.PRODUCTION_ORDER_LIST],
    path: '/production-orders/operation-barcode',
  },
  {
    name: 'Chargement',
    component: lazy(() => import('../../components/AdminInterface/ProductionOrder/UploadProductionOrder')),
    permissions: [PERMISSIONS.PRODUCTION_ORDER_UPLOAD],
    path: '/production-orders/upload',
  },
];
