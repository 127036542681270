import { Theme } from '@mui/material';

export const getSx = (theme: Theme) => ({
  outerBox: {
    width: '171px',
    height: '38px',
    marginLeft: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  iconBox: {
    width: '38px',
    height: '38px',
    background: `${theme.palette.primary.main} 0% 0% no-repeat padding-box`,
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: { width: '30px', height: '30px' },
  workstationTitle: {
    textAlign: 'left',
    margin: 0,
    padding: 0,
  },
  workstationSubtitle: {
    fontSize: '13px',
    fontWeight: 'normal',
    lineHeight: '17px',
    letterSpacing: '0.33px',
    textAlign: 'left',
    margin: 0,
    padding: 0,
    textTransform: 'none',
  },
  typoBox: { marginLeft: '14px' },
});
