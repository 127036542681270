import DvrIcon from '@mui/icons-material/Dvr';
import { DrawerMenu } from '../DashboardCardLists/menu-list';
import { PERMISSIONS } from '../roles-permissions-list';

export const DRAWER_SUPPLIER_LIST: DrawerMenu[] = [
  {
    id: 'supplier-list',
    path: '/supplier-list',
    label: 'LISTES DES FOURNISSUERS',
    subLabel: 'Liste des fournisseurs',
    icon: DvrIcon,
    color: '',
    permissions: [PERMISSIONS.SUPPLIER_LIST],
  },
];
