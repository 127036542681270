import { createSlice } from '@reduxjs/toolkit';
import { CLEAN_ALL_STATES } from '../global-actions';
import { ForecastState } from './forecast';
import {
  ADD_ARTICLE_TO_DOMAIN_FORECAST,
  CREATE_FORECAST_DOMAIN, DELETE_ARTICLE_FROM_DOMAIN_FORECAST, 
  FETCH_ALL_DOMAIN_FORECASTS, FETCH_ARTICLES_BY_DATE_RANGE, UPDATE_FORECAST_DOMAIN, 
} from './forecast.thunk';

const initialState: ForecastState = {
  list: [],
  articleList: [],
  isLoading: false,
};

export const forecastSlice = createSlice({
  name: 'forecastSlice',
  initialState,
  reducers: {
    cleanForecasts(state) {
      return {
        ...state,
        ...initialState,
      };
    },
    cleanArticlesForecasts(state) {
      return {
        ...state,
        articleList: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FETCH_ALL_DOMAIN_FORECASTS.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FETCH_ALL_DOMAIN_FORECASTS.fulfilled, (state, action) => ({
      ...state, list: action.payload, isLoading: false,
    }));
    builder.addCase(FETCH_ALL_DOMAIN_FORECASTS.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(CREATE_FORECAST_DOMAIN.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CREATE_FORECAST_DOMAIN.fulfilled, (state, action) => {
      state.articleList.push(action.payload);
      state.isLoading = false;
    });
    builder.addCase(CREATE_FORECAST_DOMAIN.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(UPDATE_FORECAST_DOMAIN.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(UPDATE_FORECAST_DOMAIN.fulfilled, (state, action) => {
      const index = state.list.findIndex((forecast) => forecast._id === action.payload._id);
    
      if (index !== -1) {
        state.list[index] = action.payload;
      }
    
      state.isLoading = false;
    });

    builder.addCase(ADD_ARTICLE_TO_DOMAIN_FORECAST.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(ADD_ARTICLE_TO_DOMAIN_FORECAST.fulfilled, (state, action) => {
      state.articleList.push(action.payload);
      state.isLoading = false;
    });
    builder.addCase(ADD_ARTICLE_TO_DOMAIN_FORECAST.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(DELETE_ARTICLE_FROM_DOMAIN_FORECAST.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(DELETE_ARTICLE_FROM_DOMAIN_FORECAST.fulfilled, (state, action) => {
      state.articleList = state.articleList.filter((article) => article._id !== action.payload._id);
      state.isLoading = false;
    });
    builder.addCase(DELETE_ARTICLE_FROM_DOMAIN_FORECAST.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(UPDATE_FORECAST_DOMAIN.rejected, (state) => {
      state.isLoading = false;
    });
    
    builder.addCase(FETCH_ARTICLES_BY_DATE_RANGE.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FETCH_ARTICLES_BY_DATE_RANGE.fulfilled, (state, action) => ({
      ...state, articleList: action.payload, isLoading: false,
    }));
    builder.addCase(FETCH_ARTICLES_BY_DATE_RANGE.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const ForecastActions = {
  ...forecastSlice.actions,
};
