import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import { OrganisationListMenuProps } from './organisation-list';
import { useAppDispatch } from '../../../../utils/hook/UseReduxHook';
import { CHANGE_CURRENT_ORGANISATION } from '../../../../store/user/user.thunk';

const OrganisationListMenu: React.FC<OrganisationListMenuProps> = ({
  anchorEl, isOpen, onClose, user, 
}) => {
  const dispatch = useAppDispatch();

  const changeCurrentOrganisation = (organisationId: string) => {
    dispatch(CHANGE_CURRENT_ORGANISATION({ organisationId, userId: user?._id }));
  };

  return (
    <Menu anchorEl={anchorEl} open={isOpen} onClose={onClose}>
      {user?.organisation?.map((organisationItem) => (
        <MenuItem
          onClick={() => {
            onClose();
            changeCurrentOrganisation(organisationItem?._id || '');
          }}
        >
          {organisationItem.code}
          {' '}
          -
          {organisationItem.description}
        </MenuItem>
      ))}
    </Menu>
  ); 
};

export default OrganisationListMenu;
