import { User } from '../../store/user/user';

interface RoleListItem {
[key: string] : any;
roles: string[];
}

export const filterListByUserRoles = <T extends RoleListItem>(list: T[], user: User): T[] => {
  const hasPlanitoolsRole = user.roles.includes('PLANITOOLS');

  return list.filter((listItem) => {
    if (hasPlanitoolsRole) {
      return true;
    }
    if (listItem.roles.length === 0) {
      return true;
    }
    return !!user.roles.find(
      (role) => listItem.roles.includes(role),
    );
  });
};

interface PermissionListItem {
  [key: string] : any;
  permissions: string[];
  }

export const filterListByUserPermissions = <T extends PermissionListItem>(list: T[], user: User): T[] => {
  const hasPlanitoolsRole = user.roles.includes('PLANITOOLS');

  return list.filter((listItem) => {
    if (hasPlanitoolsRole) {
      return true;
    }
    if (listItem.permissions.length === 0) {
      return true;
    }
    return !!user.permissions.find(
      (role) => listItem.permissions.includes(role),
    );
  });
};
