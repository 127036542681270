import { Theme } from '@mui/material';

export const getButtonSx = (theme: Theme) => ({
  'modal-cancel': {
    borderRadius: '0px',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    height: '24px',
    padding: '0',
    '&:hover': {
      backgroundColor: 'transparent',
      border: 'none',
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    margin: '1rem 2rem',
  },
  'modal-validate': {
    margin: '1rem 2rem',
  },
  'contained-dark': {
    background: `${theme.palette.info.light}`,

    '&:hover': {
      backgroundColor: '#F4F2FA',
      color: `${theme.palette.info.main}`,
    },
  },
  toolBar: {
    border: `1px solid ${theme.palette.primary.main}`,
    padding: '4px 5px',
    backgroundColor: 'transparent',
    borderRadius: '4px',
    margin: 0,
    width: '80px',
    height: '27px',
  },
  contained: {
    width: '180px',
  },
  outlined: {
    background: 'none',
    color: `${theme.palette.info.main}`,
    '&:hover': {
      backgroundColor: '#F4F2FA',
      color: `${theme.palette.info.main}`,
    },
  },
  text: {
    background: 'none',
    color: '#F4F2FA',
    '&:hover': {
      textDecoration: 'underline',
      textUnderlineOffset: '8px',
      backgroundColor: 'none',
    },
  },
});

interface StyleObject {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export const getTypoSx = (theme: Theme): StyleObject => ({
  'contained-dark': {
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: ' 0.35px',
    color: 'inherit',
  },
  contained: {
    color: '#F4F2FA',
  },
  outlined: {
    color: 'inherit',
  },
  toolBar: {
    fontWeight: 600,
    fontSize: '0.7rem',
    lineHeight: 2.5,
    fontFamily: 'Poppins',
    color: `${theme.palette.primary.main}`,
  },
});
