import { createSlice } from '@reduxjs/toolkit';
import { Organisations } from './organisation';
import { CLEAN_ALL_STATES } from '../global-actions';
import { deleteToken } from '../../utils/local-storage';
import {
  FETCH_ORGANISATIONS, CREATE_ORGANISATION, UPDATE_ORGANISATION, DELETE_ORGANISATION, 
} from './organisation.thunk';

const initialState: Organisations = {
  list: [],
  isLoading: false,
};

export const organisationSlice = createSlice({
  name: 'organisation',
  initialState,
  reducers: {
    cleanOrganisation: () => {
      deleteToken();
      return initialState;
    },
    cleanOrganisationsList: (state:Organisations) => ({
      ...state,
      list: [],
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(FETCH_ORGANISATIONS.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FETCH_ORGANISATIONS.fulfilled, (state, action) => {
      state.list = action.payload;
      state.isLoading = false;
    });
    builder.addCase(FETCH_ORGANISATIONS.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CREATE_ORGANISATION.fulfilled, (state, action) => {
      state.list = [...state.list, action.payload];
      state.isLoading = false;
    });
    builder.addCase(CREATE_ORGANISATION.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CREATE_ORGANISATION.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(UPDATE_ORGANISATION.fulfilled, (state, action) => {
      state.list = state.list.map((organisationItem) => (organisationItem._id === action.payload._id
        ? action.payload : organisationItem));
      state.isLoading = false;
    });
    builder.addCase(UPDATE_ORGANISATION.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(UPDATE_ORGANISATION.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(DELETE_ORGANISATION.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(DELETE_ORGANISATION.fulfilled, (state, action) => {
      state.list = state.list.filter((organisationItem) => (organisationItem._id !== action.payload._id));
      state.isLoading = false;
    });
    builder.addCase(DELETE_ORGANISATION.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const { cleanOrganisation, cleanOrganisationsList } = organisationSlice.actions;

export const UserActions = {
  ...organisationSlice.actions,
};
