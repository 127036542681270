import React, { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { UserListMenuProps } from './user-list';
import CustomModal from '../../../UI/CustomComponents/CustomModal';
import ResetPasswordForm from '../../../AuthLayout/ResetPasswordForm';

const UserListMenu: React.FC<UserListMenuProps> = ({
  anchorEl, isOpen, onClose, user, 
}) => {
  const [isResetPasswordModal, setIsResetPasswordModal] = useState(false);

  return (
    <>
      <Menu anchorEl={anchorEl} open={isOpen} onClose={onClose}>
        
        <MenuItem
          onClick={() => {
            onClose();
            setIsResetPasswordModal(true);
          }}
        >
          Modifier le mot de passe
        </MenuItem>
       
      </Menu>
      <CustomModal
        component={ResetPasswordForm}
        buttonType="text"
        variant="contained-dark"
        isOpen={isResetPasswordModal}
        buttonSx={{ display: 'none' }}
        closeHandler={() => setIsResetPasswordModal(false)}
      />
    </>
  ); 
};

export default UserListMenu;
