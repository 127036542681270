import { createSlice } from '@reduxjs/toolkit';
import { CLEAN_ALL_STATES } from '../global-actions';
import { CorrespondenceTableState } from './correspondence-table';
import {
  CREATE_CORRESPONDENCE_TABLE, 
  CREATE_MAPPING_OBJECT, 
  FETCH_CORRESPONDENCE_TABLES, UPDATE_CORRESPONDENCE_TABLE, 
} from './correspondence-table.thunk';

const initialState: CorrespondenceTableState = {
  list: [],
  isLoading: false,
};

export const correspondenceTableSlice = createSlice({
  name: 'correspondenceTableSlice',
  initialState,
  reducers: {
    cleanCorrespondenceTable(state) {
      return {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FETCH_CORRESPONDENCE_TABLES.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FETCH_CORRESPONDENCE_TABLES.fulfilled, (state, action) => ({
      ...state, list: action.payload, isLoading: false,
    }));
    builder.addCase(FETCH_CORRESPONDENCE_TABLES.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(UPDATE_CORRESPONDENCE_TABLE.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(UPDATE_CORRESPONDENCE_TABLE.fulfilled, (state, action) => {
      state.list = state.list.map((rowItem) => (rowItem._id === action.payload._id
        ? action.payload : rowItem));
      state.isLoading = false;
    });
    builder.addCase(UPDATE_CORRESPONDENCE_TABLE.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CREATE_CORRESPONDENCE_TABLE.fulfilled, (state, action) => {
      state.list = [...state.list, action.payload];
      state.isLoading = false;
    });
    builder.addCase(CREATE_CORRESPONDENCE_TABLE.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CREATE_CORRESPONDENCE_TABLE.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CREATE_MAPPING_OBJECT.fulfilled, (state, action) => {
      state.list = state.list.map((rowItem) => (rowItem._id === action.payload._id
        ? action.payload : rowItem));
      state.isLoading = false;
    });
    builder.addCase(CREATE_MAPPING_OBJECT.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CREATE_MAPPING_OBJECT.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const correspondenceTableActions = {
  ...correspondenceTableSlice.actions,
};
