import { createSlice } from '@reduxjs/toolkit';
import { CLEAN_ALL_STATES } from '../global-actions';
import { TimeTrackerState } from './time-tracker';
import { FETCH_ACTIVITY_TIME_REPORT } from './time-tracker.thunk';

const initialState: TimeTrackerState = {
  activityListReport: [],
  isLoading: false,
};

export const timeTrackerSlice = createSlice({
  name: 'timeTrackerSlice',
  initialState,
  reducers: {
    cleanTimeTracking(state) {
      return {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FETCH_ACTIVITY_TIME_REPORT.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FETCH_ACTIVITY_TIME_REPORT.fulfilled, (state, action) => ({
      ...state, activityListReport: action.payload, isLoading: false,
    }));
    builder.addCase(FETCH_ACTIVITY_TIME_REPORT.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const { cleanTimeTracking } = timeTrackerSlice.actions;

export const timeTrackerActions = {
  ...timeTrackerSlice.actions,
};
