import { createAsyncThunk } from '@reduxjs/toolkit';
import { UiSlice } from '../ui/ui.slice';
import axiosApiInstance from '../../config/axios';
import { ErrorApiModel } from '../../config/error-api.model';
import { CreateProductionLineDto, UpdateProductionLineDto } from './production-line';

const { displayAlert, displayLoader } = UiSlice.actions;

const url: string = process.env.REACT_APP_API_BASE_URI || '/api';

export const FETCH_ALL_PRODUCTION_LINES = createAsyncThunk(
  'production-lines/FETCH_ALL_OPERATIONS',
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.get(
        `${url}/production-lines`,
      );
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const FETCH_PRODUCTION_LINE_BY_CODE = createAsyncThunk(
  'production-lines/FETCH_OPERATION_BY_CODE',
  async (code: string, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.get(
        `${url}/production-lines/${code}`,
      );
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const CREATE_PRODUCTION_LINE = createAsyncThunk(
  'production-lines/CREATE_PRODUCTION_LINE',
  async (payload: CreateProductionLineDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(`${url}/production-lines`, payload);
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const UPDATE_PRODUCTION_LINE = createAsyncThunk(
  'production-lines/UPDATE_PRODUCTION_LINE',
  async (payload: UpdateProductionLineDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.patch(`${url}/production-lines`, payload);
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);
