import React from 'react';
import { ChevronRight } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import { FORGOT_PASSWORD } from '../../../store/user/user.thunk';
import { FormValues } from './forgot-password';
import { useAppDispatch, useAppSelector } from '../../../utils/hook/UseReduxHook';
import CustomTypography from '../../UI/CustomComponents/CustomTypography';
import CustomControlledTextField from '../../UI/CustomComponents/CustomControlledTextField';
import CustomButton from '../../UI/CustomComponents/CustomButton';
import { AuthLayout } from '..';
import { forgotPasswordSchema } from '../../../utils/form-validation-schema/user/forgot-password';
import { getSx } from '../sx';

const ForgotPassword: React.FC = () => {
  const theme = useTheme();
  const sx = getSx(theme);
  const dispatch = useAppDispatch();
  const userIsLoading = useAppSelector((state) => state.user.isLoading);
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(forgotPasswordSchema),
  });

  const onSubmit = async (data: FormValues) => {
    await dispatch(FORGOT_PASSWORD({ ...data }));
    navigate('/login');
  };

  return (
    <AuthLayout>
      <Box sx={sx.boxTitle}>
        <CustomTypography title="Réinitialiser" variant="h1" />
        <CustomTypography
          title="votre mot de passe"
          variant="h1"
          sx={{ fontWeight: 'normal' }}
        />
      </Box>
      <form noValidate onSubmit={handleSubmit(onSubmit)} id="login-admin">
        <Box sx={{ ...sx.boxTypographie, height: '15vh' }}>
          <CustomControlledTextField
            variant="outlined"
            required
            id="email"
            label="Adresse Email"
            autocomplete="email"
            name="email"
            control={control}
            error={!!errors.email}
            errorMessage={errors.email ? errors.email.message : ''}
            size="small"
            noWrapper
            sx={sx.typoCode}
            inputProps={{
              sx: sx.typoCodeInputProps,
            }}
            InputLabelProps={{
              shrink: true,
              sx: sx.typoCodeInputLabelsProps,
            }}
          />

          <CustomButton
            title="Envoyer le lien de réinitialisation"
            type="submit"
            fullWidth
            variant="contained"
            bigButton
            isLoading={userIsLoading}
            endIcon={<ChevronRight />}
            sx={sx.button}
            typographySx={sx.buttonTypo}
          />
        </Box>
      </form>
      <CustomButton
        title="Retour à la connexion"
        type="link"
        startIcon={<ChevronLeftIcon color="primary" />}
        size="small"
        variant="text"
        handler={() => navigate('/login')}
        sx={{
          fontWeight: 700,
          fontSize: '15px',
          width: '17rem',
          margin: '1rem auto',
        }}
        typographySx={{ color: `${theme.palette.primary.main} !important` }}
      />
    </AuthLayout>
  );
};

export default ForgotPassword;
