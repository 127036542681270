import UploadIcon from '@mui/icons-material/Upload';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { DrawerMenu } from '../DashboardCardLists/menu-list';
import { PERMISSIONS } from '../roles-permissions-list';

export const DRAWER_PRODUCTION_ORDERS_LIST: DrawerMenu[] = [
  {
    id: 'production-orders',
    path: '/production-orders/list',
    label: 'LISTE OF',
    subLabel: 'Liste des OF',
    icon: PrecisionManufacturingIcon,
    color: '',
    permissions: [PERMISSIONS.PRODUCTION_ORDER_LIST],
  },
  {
    id: 'operation-barcode',
    path: '/production-orders/operation-barcode',
    label: 'CODES-BARRES',
    subLabel: 'Codes-barres Opération',
    icon: QrCode2Icon,
    color: '',
    permissions: [PERMISSIONS.PRODUCTION_ORDER_LIST],
  },
  {
    id: 'upload-production-orders',
    path: '/production-orders/upload',
    label: 'CHARGEMENT',
    subLabel: 'upload',
    icon: UploadIcon,
    color: '',
    permissions: [PERMISSIONS.PRODUCTION_ORDER_UPLOAD],
  },
];
