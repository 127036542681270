import React, { useState, useEffect } from 'react';
import {
  Modal,
  Fade,
  IconButton,
  CardActionArea,
  Card,
  CardContent,
  useTheme,
  Tooltip,
} from '@mui/material';
import CustomTypography from '../CustomTypography';
import CustomButton from '../CustomButton';
import { modalProps, buttonProps, typographyProps } from './custom-modal';

const CustomModal = React.forwardRef(
  (
    {
      component: Component,
      icon: Icon = undefined,
      startIcon: StartIcon = undefined,
      endIcon: EndIcon = undefined,
      addButtonText = '',
      buttonType = 'button',
      fullScreen = false,
      modalSx = {},
      openHandler = null,
      disabledButton = false,
      colorText = 'inherit',
      closeHandler = null,
      color = 'primary',
      rest = {},
      fullWidth = false,
      isStoppingPropagation = false,
      variant = 'text',
      iconProps = {},
      iconCardProps = { style: { fontSize: 50 } },
      isOpen = false,
      buttonSx = {},
      buttonTypoSx = {},
      disabledBackDropClick = false,
      backdropStyle,
      tooltipTitle,
    }: modalProps & buttonProps & typographyProps,
    ref,
  ) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
      setOpen(isOpen);
    }, [isOpen]);

    const handleOpen = (event?: unknown) => {
      if (openHandler) {
        openHandler();
      }
      setOpen(true);
      if (event && isStoppingPropagation) {
        (event as Event).stopPropagation();
      }
    };

    const handleClose = (event: unknown, reason: string) => {
      if (isStoppingPropagation) {
        (event as Event).stopPropagation();
      }
      if (reason === 'backdropClick' && disabledBackDropClick) return;
      if (reason === 'escapeKeyDown' && disabledBackDropClick) return;
      if (closeHandler) {
        closeHandler();
      }
      setOpen(false);
    };

    const theme = useTheme();

    return (
      <>
        {buttonType === 'tile' && (
          <Card
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              margin: 'auto',
              height: '100%',
            }}
            onClick={handleOpen}
          >
            <CardActionArea>
              <CardContent sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              >
                <div>
                  (
                  {Icon && <Icon {...iconCardProps} />}
                  )
                </div>
                <CustomTypography variant="h5" title={addButtonText} />
              </CardContent>
            </CardActionArea>
          </Card>
        )}
        {buttonType === 'button' && (
          <CustomButton
            ref={ref}
            variant={variant}
            title={addButtonText}
            color={color}
            disabled={disabledButton}
            handler={handleOpen}
            startIcon={
              (StartIcon && <StartIcon {...iconProps} />)
              || (Icon && <Icon {...iconProps} />)
            }
            endIcon={EndIcon && <EndIcon />}
            fullWidth={fullWidth}
            sx={buttonSx}
            typographySx={buttonTypoSx}
          />
        )}
        {buttonType === 'icon' && (
          (tooltipTitle ? (
            <Tooltip title={tooltipTitle}>
              <IconButton sx={{ color }} component="span" onClick={handleOpen}>
                {Icon && <Icon {...iconProps} />}
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton sx={{ color }} component="span" onClick={handleOpen}>
              {Icon && <Icon {...iconProps} />}
            </IconButton>
          ))
        )}
        {buttonType === 'link' && (
          <CustomButton
            ref={ref}
            title={`${addButtonText}`}
            variant={variant}
            handler={handleOpen}
            size="medium"
            color={color}
            sx={buttonSx}
            typographySx={buttonTypoSx}
          />
        )}
        {buttonType === 'text' && (
          <CustomButton
            ref={ref}
            title={`${addButtonText}`}
            variant={variant}
            handler={handleOpen}
            size="medium"
            color={colorText}
            sx={buttonSx}
            typographySx={buttonTypoSx}
          />
        )}

        <Modal
          aria-labelledby="modale"
          sx={{
            ...(!fullScreen ? {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'auto',
              maxWidth: '90vw',
              minWidth: '60vw',
              margin: 'auto',
            } : {
              width: '100vw',
              height: '100vh',
              padding: '1rem',
            }),
            ...modalSx,
          }}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slotProps={{ backdrop: { style: backdropStyle } }}
        >
          <Fade in={open}>
            <div style={{ backgroundColor: 'white' }}>
              <Component
                handleModalClose={handleClose}
                handleModalOpen={handleOpen}
                open={open}
                {...rest}
              />
            </div>
          </Fade>
        </Modal>
      </>
    );
  },
);

// help for debugging - the console will show this name
CustomModal.displayName = 'CustomModal';

export default CustomModal;
