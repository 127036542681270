import React from 'react';
import { Box } from '@mui/material';

const LoginImageBackground: React.FC = () => (
  <Box sx={{
    height: '90vh',
    width: '45vw',
    borderRadius: '1rem',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: 'url(/images/background1.jpg)',
    backgroundSize: 'cover',
    color: '#fff',
  }}
  >
    <img
      src="/images/logoPlanitools.jpg"
      alt="logo de la socité Planitools"
      style={{ borderRadius: '4rem' }}
    />

  </Box>
);
export default LoginImageBackground;
