import UploadIcon from '@mui/icons-material/Upload';
import CodeIcon from '@mui/icons-material/Code';
import { DrawerMenu } from '../DashboardCardLists/menu-list';
import { PERMISSIONS } from '../roles-permissions-list';

export const DRAWER_UPLOAD_RULES_LIST: DrawerMenu[] = [
  {
    id: 'upload-rules',
    path: '/upload-rules/list',
    label: 'REGLES D\'IMPORT',
    subLabel: 'Liste des règles',
    icon: UploadIcon,
    color: '',
    permissions: [PERMISSIONS.UPLOAD_RULE_LIST],
  },
  {
    id: 'upload-specific-script',
    path: '/upload-rules/scripts',
    label: 'SCRIPTS SPECIFIQUES',
    subLabel: 'Liste des scripts',
    icon: CodeIcon,
    color: '',
    permissions: [PERMISSIONS.SCRIPT_SPECIFIC],
  },
];
