import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import { CustomDrawerProps } from './custom-drawer';

const CustomDrawer: React.FC<CustomDrawerProps> = ({
  menuList, isExpanded, setIsExpanded, drawerWidth, collapsedDrawerWidth,
}) => {
  const theme = useTheme();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: isExpanded ? drawerWidth : collapsedDrawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isExpanded ? drawerWidth : collapsedDrawerWidth,
          boxSizing: 'border-box',
          backgroundColor: theme.palette.secondary.light,
          transition: 'width 0s',
        },
      }}
      PaperProps={{
        style: {
          marginTop: '64px',
        },
      }}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <List
        sx={{
          backgroundColor: theme.palette.secondary.light,
        }}
      >
        {menuList.map((menuItem) => (
          <ListItem button key={menuItem.label} component={RouterLink} to={menuItem.path}>
            {menuItem.icon && (
              <ListItemIcon>
                <menuItem.icon sx={{ fontSize: '2.5rem', color: theme.palette.secondary.main }} />
              </ListItemIcon>
            )}
            {isExpanded
            && <ListItemText primary={menuItem.label} primaryTypographyProps={{ sx: { fontWeight: '600' } }} />}
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default CustomDrawer;
