import { lazy } from 'react';
import { RoutesDefinition } from '../routes';
import { PERMISSIONS } from '../../utils/Lists/roles-permissions-list';

export const articlesRoutes: RoutesDefinition[] = [
  {
    name: 'Article Root',
    component: lazy(() => import('../../components/UI/DrawerRootEntry')),
    permissions: [],
    path: '/articles',
  },
  {
    name: 'Liste Articles',
    component: lazy(() => import('../../components/AdminInterface/Articles/ListArticles')),
    permissions: [PERMISSIONS.ARTICLE_LIST],
    path: '/articles/list',
  },
  {
    name: 'Paramètres',
    component: lazy(() => import('../../components/AdminInterface/Articles/ConfArticles')),
    permissions: [PERMISSIONS.ARTICLE_CONF],
    path: '/articles/params',
  },
  {
    name: 'Chargement',
    component: lazy(() => import('../../components/AdminInterface/Articles/UploadArticles')),
    permissions: [PERMISSIONS.ARTICLE_UPLOAD],
    path: '/articles/upload',
  },
];
