import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { LicenseInfo } from '@mui/x-license';
import App from './components/App';
import Store from './store';
import theme from './theme';
import CustomLoader from './components/UI/CustomComponents/CustomLoader';
import './index.css';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY as string);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Provider store={Store}>
      <Suspense fallback={<CustomLoader />}>
        <Router>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </Router>
      </Suspense>
    </Provider>
  </React.StrictMode>,
);
