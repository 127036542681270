import { createSlice } from '@reduxjs/toolkit';
import { CLEAN_ALL_STATES } from '../global-actions';
import { CREATE_UNIT, FETCH_ALL_UNITS, UPDATE_UNIT } from './unit.thunk';
import { UnitState } from './unit';

const initialState: UnitState = {
  list: [],
  isLoading: false,
};

export const unitSlice = createSlice({
  name: 'unitSlice',
  initialState,
  reducers: {
    cleanUnits(state) {
      return {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FETCH_ALL_UNITS.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(FETCH_ALL_UNITS.fulfilled, (state, action) => ({
      ...state, list: action.payload, isLoading: false,
    }));
    builder.addCase(FETCH_ALL_UNITS.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CREATE_UNIT.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CREATE_UNIT.fulfilled, (state, action) => {
      state.list.push(action.payload);
      state.isLoading = false;
    });
    builder.addCase(CREATE_UNIT.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(UPDATE_UNIT.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(UPDATE_UNIT.fulfilled, (state, action) => {
      state.list = state.list.map((unitItem) => (unitItem._id === action.payload._id
        ? action.payload : unitItem));
      state.isLoading = false;
    });
    builder.addCase(UPDATE_UNIT.rejected, (state) => {
      state.isLoading = false;
    }); 
    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const { cleanUnits } = unitSlice.actions;

export const unitActions = {
  ...unitSlice.actions,
};
