import { createSlice } from '@reduxjs/toolkit';
import { TaskState } from './task';
import { CREATE_TASK, GET_TASKS } from './task.thunk';
import { CLEAN_ALL_STATES } from '../global-actions';

const initialState: TaskState = {
  list: [],
  isLoading: false,
};

export const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    clearTasks() {
      return {
        ...initialState,
      };
    },
    addNewTask(state, action) {
      const taskIndex = state.list.findIndex((task) => task._id === action.payload._id);
      if (taskIndex !== -1) {
        state.list[taskIndex] = action.payload;
      } else {
        state.list.push(action.payload); 
      }
    },
    updateTask(state, action) {
      state.list = state.list.map((taskItem) => {
        if (taskItem._id === action.payload._id) {
          return { ...action.payload, id: action.payload._id };
        }
        return { ...taskItem };
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(CREATE_TASK.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(CREATE_TASK.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CREATE_TASK.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(GET_TASKS.fulfilled, (state, action) => ({
      ...state, list: action.payload, isLoading: false,
    }));
    builder.addCase(GET_TASKS.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(GET_TASKS.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const TasksActions = {
  ...taskSlice.actions,
  CREATE_TASK,
  GET_TASKS,
};
