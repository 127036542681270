import { createSlice } from '@reduxjs/toolkit';
import { ColumnList } from './column.d';
import { CLEAN_ALL_STATES } from '../global-actions';
import {
  CREATE_COLLECTION_COLUMN, DELETE_COLLECTION_COLUMN, GET_COLLECTION_COLUMNS, UPDATE_COLLECTION_COLUMN,
} from './column.thunk';

const initialState: ColumnList = {
  list: [],
  isLoading: false,
};

export const columnSlice = createSlice({
  name: 'columnSlice',
  initialState,
  reducers: {
    cleanColumns(state) {
      return {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GET_COLLECTION_COLUMNS.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(GET_COLLECTION_COLUMNS.fulfilled, (state, action) => ({
      ...state, list: action.payload, isLoading: false,
    }));
    builder.addCase(GET_COLLECTION_COLUMNS.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CREATE_COLLECTION_COLUMN.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CREATE_COLLECTION_COLUMN.fulfilled, (state, action) => {
      state.list.push(action.payload);
    });
    builder.addCase(CREATE_COLLECTION_COLUMN.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(UPDATE_COLLECTION_COLUMN.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(UPDATE_COLLECTION_COLUMN.fulfilled, (state, action) => {
      state.list = state.list.map((columnItem) => (columnItem._id === action.payload._id
        ? action.payload : columnItem));
      state.isLoading = false;
    });
    builder.addCase(UPDATE_COLLECTION_COLUMN.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(DELETE_COLLECTION_COLUMN.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(DELETE_COLLECTION_COLUMN.fulfilled, (state, action) => {
      state.list = state.list.filter((columnItem) => (columnItem._id !== action.payload._id));
      state.isLoading = false;
    });
    builder.addCase(DELETE_COLLECTION_COLUMN.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const { cleanColumns } = columnSlice.actions;

export const ColumnActions = {
  ...columnSlice.actions,
};
