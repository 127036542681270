import React from 'react';
import { AuthLayout } from '..';
import ResetPasswordForm from '../ResetPasswordForm';

const ResetPasswordPage = () => (
  <AuthLayout>
    <ResetPasswordForm />
  </AuthLayout>
);

export default ResetPasswordPage;
