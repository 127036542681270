import { createAsyncThunk } from '@reduxjs/toolkit';
import { UiSlice } from '../ui/ui.slice';
import axiosApiInstance from '../../config/axios';
import { ErrorApiModel } from '../../config/error-api.model';
import {
  CreateRoutingDto, SaveCoordinatesDto, UpdateRoutingDto, 
} from './routing';

const { displayAlert, displayLoader } = UiSlice.actions;

const url: string = process.env.REACT_APP_API_BASE_URI || '/api';

export const FETCH_ALL_ROUTINGS = createAsyncThunk(
  'routing/FETCH_ALL_ROUTINGS',
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.get(
        `${url}/routing`,
      );
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const FETCH_ROUTING_BY_CODE = createAsyncThunk(
  'routing/FETCH_ROUTING_BY_CODE',
  async (routingCode: string, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.get(
        `${url}/routing/${routingCode}`,
      );
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const CREATE_ROUTING = createAsyncThunk(
  'routing/CREATE_ROUTING',
  async (payload: CreateRoutingDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(`${url}/routing`, payload);
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const UPDATE_ROUTING = createAsyncThunk(
  'routing/UPDATE_ROUTING',
  async (payload: UpdateRoutingDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.patch(`${url}/routing`, payload);
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const SAVE_CARTO_COORDINATES = createAsyncThunk(
  'routing/SAVE_CARTO_COORDINATES',
  async (payload: SaveCoordinatesDto, thunkApi) => {
    try {
      const result = await axiosApiInstance.patch(
        `${url}/routing/mapping-coordinates`,
        payload,
      );
      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);
