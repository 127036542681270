import { createAsyncThunk } from '@reduxjs/toolkit';
import { UiSlice } from '../ui/ui.slice';
import axiosApiInstance from '../../config/axios';
import { ErrorApiModel } from '../../config/error-api.model';
import { CreateNomencCoefDto, DeleteNomencCoefDto, UploadNomencFileDto } from './nomenc';

const { displayAlert, displayLoader } = UiSlice.actions;

const url: string = process.env.REACT_APP_API_BASE_URI || '/api';

export const GET_ARTICLE_INDICES = createAsyncThunk(
  'nomenc/GET_ARTICLE_INDICES',
  async (articleCode: string, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.get(
        `${url}/nomenc/formatted/indices/${articleCode}`,
      );
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const FETCH_ALL_NOMENC_COEF = createAsyncThunk(
  'nomenc/FETCH_ALL_NOMENC_COEF',
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.get(
        `${url}/nomenc/coef`,
      );
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const FETCH_ALL_NOMENC_FORMATTED = createAsyncThunk(
  'nomenc/FETCH_ALL_NOMENC_FORMATTED',
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.get(
        `${url}/nomenc/formatted`,
      );
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const FETCH_FULL_BOM = createAsyncThunk(
  'nomenc/FETCH_FULL_BOM',
  async ({ code, indice }:{code: string, indice: string}, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.get(`${url}/nomenc/formatted/full`, {
        params: {
          code,
          indice,
        },
      });
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const UPLOAD_NOMENC_COEF_FILE = createAsyncThunk(
  'nomenc/UPLOAD_NOMENC_COEF_FILE',
  async (payload: UploadNomencFileDto, thunkApi) => {
    try {
      const { file, uploadRuleCode } = payload;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('uploadRuleCode', uploadRuleCode);

      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(`${url}/nomenc/coef/upload-file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Fichier envoyé avec succès',
          color: 'success',
          timeout: 6000,
        }),
      );

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Problème lors de l\'envoie du fichier',
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const DELETE_NOMENC_COEF = createAsyncThunk(
  'nomenc/DELETE_NOMENC_COEF',
  async (payload: DeleteNomencCoefDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.delete(`${url}/nomenc/coef`, {
        params: payload,
      });
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Ligne supprimée avec succès',
          color: 'success',
          timeout: 6000,
        }),
      );

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const UPDATE_NOMENC_COEF = createAsyncThunk(
  'nomenc/UPDATE_NOMENC_COEF',
  async (payload: CreateNomencCoefDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.patch(`${url}/nomenc/coef`, payload);
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Ligne modifiée avec succès',
          color: 'success',
          timeout: 6000,
        }),
      );

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const CREATE_NOMENC_COEF = createAsyncThunk(
  'nomenc/CREATE_NOMENC_COEF',
  async (payload: CreateNomencCoefDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(`${url}/nomenc/coef`, payload);
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Ligne créée avec succès',
          color: 'success',
          timeout: 6000,
        }),
      );

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);
