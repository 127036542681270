import { createSlice } from '@reduxjs/toolkit';
import { ActionState } from './action';
import { CREATE_ACTION, GET_ACTIONS } from './action.thunk';
import { CLEAN_ALL_STATES } from '../global-actions';

const initialState: ActionState = {
  list: [],
  isLoading: false,
};

export const actionSlice = createSlice({
  name: 'action',
  initialState,
  reducers: {
    clearActions() {
      return {
        ...initialState,
      };
    },
    addNewAction(state, action) {
      const actionIndex = state.list.findIndex((actionItem) => actionItem._id === action.payload._id);
      if (actionIndex !== -1) {
        state.list[actionIndex] = action.payload;
      } else {
        state.list.push(action.payload);
      }
    },
    updateAction(state, action) {
      state.list = state.list.map((actionItem) => {
        if (actionItem._id === action.payload._id) {
          return { ...action.payload, id: action.payload._id };
        }
        return { ...actionItem };
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(CREATE_ACTION.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(CREATE_ACTION.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CREATE_ACTION.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(GET_ACTIONS.fulfilled, (state, action) => ({
      ...state, list: action.payload, isLoading: false,
    }));
    builder.addCase(GET_ACTIONS.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(GET_ACTIONS.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const ActionsActions = {
  ...actionSlice.actions,
  CREATE_ACTION,
  GET_ACTIONS,
};
