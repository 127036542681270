import { lazy } from 'react';
import { RoutesDefinition } from '../routes';
import { PERMISSIONS } from '../../utils/Lists/roles-permissions-list';

export const stockRoutes: RoutesDefinition[] = [
  {
    name: 'Stock Root',
    component: lazy(() => import('../../components/UI/DrawerRootEntry')),
    permissions: [],
    path: '/stock',
  },
  {
    name: 'Qté Stock',
    component: lazy(() => import('../../components/AdminInterface/Stock/ListStock')),
    permissions: [PERMISSIONS.STOCK_LIST],
    path: '/stock/list',
  },
  {
    name: 'Paramètres',
    component: lazy(() => import('../../components/AdminInterface/Stock/ConfStock')),
    permissions: [PERMISSIONS.STOCK_CONF],
    path: '/stock/params',
  },
  {
    name: 'Chargement',
    component: lazy(() => import('../../components/AdminInterface/Stock/UploadStock')),
    permissions: [PERMISSIONS.STOCK_UPLOAD],
    path: '/stock/upload',
  },
];
